import React, { Component } from "react";

export default class EntreImg extends Component {
  render() {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2133.333"
      height="1360.107"
      version="1.1"
      viewBox="0 0 2133.333 1360.107"
      xmlSpace="preserve"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 1360.107)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path fill="#fff" d="M0 0h16000v10200.8H0z"></path>
          <path
            fill="#f5f5ff"
            d="M197.324 3270S468.609 5747.8 1712.73 6159.18c1136.88 375.92 1707.31 15.7 2063.02-344.51 285.61-289.22 432.8-578.45 623.63-486.64 428.49 206.16 1321.89 2653.35 3849.96 2674.51 1118.38 9.36 1805.26-737.3 2399.76-1483.96 750.8-942.81 1354.4-1885.61 2494-1306.36C14583.8 5944.56 15868 3270 15868 3270H197.324"
          ></path>
          <path
            fill="#d0dcfc"
            d="M7242.23 5123.32h6423.47v4001.43H7242.23z"
          ></path>
          <path
            fill="#fdfeff"
            d="M8045.12 5727.94l-23 1593.85 34.3.49 22.99-1593.85-34.29-.49"
          ></path>
          <path
            fill="#46557c"
            d="M13810.1 8976.12H7144.85v160.06h6665.25v-160.06"
          ></path>
          <path
            fill="#46557c"
            d="M7144.85 5031.85h6665.25v160.059H7144.85z"
          ></path>
          <path
            fill="#fff"
            d="M8505.34 7402.85c0-59.28-48.05-107.33-107.32-107.33h-631.37c-59.28 0-107.32 48.05-107.32 107.33v128.33c0 59.27 48.04 107.32 107.32 107.32h631.37c59.27 0 107.32-48.05 107.32-107.32v-128.33"
          ></path>
          <path
            fill="#fff"
            d="M8551.07 6742.39c0-45.12-44.37-87.1-100.7-87.1h-613.61c-56.32 0-97.41 41.98-97.41 87.1v103.04c0 45.12 41.09 84.24 97.41 84.24h613.61c56.33 0 100.7-39.12 100.7-84.24v-103.04"
          ></path>
          <path
            fill="#fff"
            d="M8379.58 6164.86c0-58.75-26.4-104.07-71.52-104.07h-491.58c-45.12 0-77.13 45.32-77.13 104.07V6299c0 58.74 32.01 104.78 77.13 104.78h491.58c45.12 0 71.52-46.04 71.52-104.78v-134.14"
          ></path>
          <path
            fill="#fff"
            d="M8391.02 5673.75c0-45.12-36.59-81.69-81.7-81.69h-488.27c-45.12 0-81.7 36.57-81.7 81.69v110.99c0 45.12 36.58 81.7 81.7 81.7h488.27c45.11 0 81.7-36.58 81.7-81.7v-110.99"
          ></path>
          <path
            fill="#46557c"
            d="M13524.3 8450.22h-651.7v57.16h651.7v-57.16"
          ></path>
          <path
            fill="#46557c"
            d="M13524.3 7844.29h-651.7v57.16h651.7v-57.16"
          ></path>
          <path
            fill="#46557c"
            d="M13524.3 7169.76h-651.7v57.17h651.7v-57.17"
          ></path>
          <path
            fill="#46557c"
            d="M13524.3 6496.53h-651.7v57.16h651.7v-57.16"
          ></path>
          <path
            fill="#46557c"
            d="M13524.3 6026.5h-651.7v57.16h651.7v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M8391.02 7489.88h-571.64v57.16h571.64v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M8391.02 7375.55h-571.64v57.17h571.64v-57.17"
          ></path>
          <path
            fill="#93a2f9"
            d="M8425.31 6758.19h-583.06v57.16h583.06v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M8253.82 6243.72h-434.44v57.16h434.44v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M8265.25 5694.95h-434.43v57.16h434.43v-57.16"
          ></path>
          <path
            fill="#0c0c0c"
            d="M9803.6 5713.07l-.08 11.43 2897.58 21.71v2806.91h11.5V5734.86l-5.7-.04-2903.3-21.75"
          ></path>
          <path
            fill="#fdfeff"
            d="M10038.8 5913.26l-27 21.11c4.5 5.84 464.7 585.42 1269.7 754.17 274.5 57.54 475.4 567.95 652.7 1018.27 162.1 411.91 302.2 767.66 486.2 816.05l8.7-33.17c-167.2-43.97-310.9-408.99-463-795.45-189.3-480.79-385-977.93-677.6-1039.28-792.3-166.08-1245.2-735.96-1249.7-741.7"
          ></path>
          <path
            fill="#fff"
            d="M10323.1 7837.29c0-59.28-48-107.33-107.3-107.33h-631.36c-59.27 0-107.32 48.05-107.32 107.33v128.33c0 59.27 48.05 107.32 107.32 107.32h631.36c59.3 0 107.3-48.05 107.3-107.32v-128.33"
          ></path>
          <path
            fill="#fff"
            d="M9488.55 7012.38c0-59.27-37.06-105.57-85.21-105.57h-516.95c-48.16 0-83.8 46.3-83.8 105.57v132.47c0 59.27 35.64 104.94 83.8 104.94h516.95c48.15 0 85.21-45.67 85.21-104.94v-132.47"
          ></path>
          <path
            fill="#fff"
            d="M9557.15 6396.96c0-29.4-38.33-61.78-83.45-61.78h-491.58c-45.12 0-76.63 32.38-76.63 61.78v67.15c0 29.41 31.51 53.99 76.63 53.99h491.58c45.12 0 83.45-24.58 83.45-53.99v-67.15"
          ></path>
          <path
            fill="#fff"
            d="M9557.15 5849.66c0-47.35-38.33-86.11-83.45-86.11h-491.58c-45.12 0-76.63 38.76-76.63 86.11v108.14c0 47.35 31.51 80.13 76.63 80.13h491.58c45.12 0 83.45-32.78 83.45-80.13v-108.14"
          ></path>
          <path
            fill="#fff"
            d="M8985.52 8411.95c0-73.57-59.65-133.22-133.23-133.22h-785.37c-73.57 0-133.21 59.65-133.21 133.22v156.57c0 73.58 59.64 133.22 133.21 133.22h785.37c73.58 0 133.23-59.64 133.23-133.22v-156.57"
          ></path>
          <path
            fill="#fdfeff"
            d="M8181.39 7630.98l-31.64 13.26 265.15 632.77 31.65-13.25-265.16-632.78"
          ></path>
          <path
            fill="#fdfeff"
            d="M9176.86 7194.62l-502.89 1068.46 31.04 14.61 502.88-1068.46-31.03-14.61"
          ></path>
          <path
            fill="#93a2f9"
            d="M8756.86 8518.82H8105.2v57.16h651.66v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M10231.7 7890.02h-651.69v57.16h651.69v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M8562.5 8393.06h-445.87v57.16h445.87v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M9454.25 6403.78h-445.87v57.16h445.87v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M9454.25 5877.87h-445.87v57.17h445.87v-57.17"
          ></path>
          <path
            fill="#93a2f9"
            d="M9339.92 7101.17h-434.43v57.16h434.43v-57.16"
          ></path>
          <path
            fill="#93a2f9"
            d="M9339.92 6998.27h-434.43v57.17h434.43v-57.17"
          ></path>
          <path
            fill="#fff"
            d="M12786.2 7883.83c0-38.75-33.9-70.16-75.6-70.16-41.7 0-75.6 31.41-75.6 70.16s33.9 70.17 75.6 70.17c41.7 0 75.6-31.42 75.6-70.17"
          ></path>
          <path
            fill="#fff"
            d="M12775 7178.4c0-38.75-33.9-70.17-75.6-70.17-41.7 0-75.6 31.42-75.6 70.17s33.9 70.17 75.6 70.17c41.7 0 75.6-31.42 75.6-70.17"
          ></path>
          <path
            fill="#fff"
            d="M12775 6517.76c0-38.75-33.9-70.17-75.6-70.17-41.7 0-75.6 31.42-75.6 70.17s33.9 70.17 75.6 70.17c41.7 0 75.6-31.42 75.6-70.17"
          ></path>
          <path
            fill="#fff"
            d="M12786.2 6047.47c0-38.75-33.9-70.16-75.6-70.16-41.7 0-75.6 31.41-75.6 70.16s33.9 70.17 75.6 70.17c41.7 0 75.6-31.42 75.6-70.17"
          ></path>
          <path
            fill="#fff"
            d="M12775 8522.08c0-38.75-33.9-70.16-75.6-70.16-41.7 0-75.6 31.41-75.6 70.16s33.9 70.17 75.6 70.17c41.7 0 75.6-31.42 75.6-70.17"
          ></path>
          <path
            fill="#fdfeff"
            d="M9287.04 6507.08l-156.77 414.3 32.09 12.14 156.76-414.3-32.08-12.14"
          ></path>
          <path
            fill="#fdfeff"
            d="M9793.99 8002.71l-811.8 386.15 14.73 30.97 811.81-386.15-14.74-30.97"
          ></path>
          <path
            fill="#fdfeff"
            d="M9149.9 6024l-30.68 15.34 151.24 302.32 30.68-15.34L9149.9 6024"
          ></path>
          <path
            fill="#91a0d6"
            d="M10277.9 7150.99c-18.6 25.2-38.6 53.16-58.2 81.03l80.6 37.84-22.4-118.87"
          ></path>
          <path fill="#f0f0ff" d="M0 0h16000v1730H0z"></path>
          <path fill="#d0dcfc" d="M0 1730h16000v1200H0z"></path>
          <path fill="#e9f0ff" d="M0 2930h16000v370H0z"></path>
          <path fill="#ebebfc" d="M11520 1630H9200v330h2320v-330"></path>
          <path fill="#ebebfc" d="M11690 1950H9370v320h2320v-320"></path>
          <path fill="#ebebfc" d="M11890 2270H9570v340h2320v-340"></path>
          <path fill="#ebebfc" d="M12070 2590H9750v340h2320v-340"></path>
          <path
            fill="#ddddfc"
            d="M9783.61 1272.38c-291.56 0-557.27 9.66-757.28 25.5 7.49 46.96 15.29 96.17 23.28 147.1 55.62 4.17 116 7.87 180.28 11.04l-19.62-85.48 30.66-.54 20.07 87.5c156.2 7.14 333.96 11.18 522.61 11.18 621.99 0 1126.19-43.94 1126.19-98.14 0-54.21-504.2-98.16-1126.19-98.16m-1092.02 74.05c-22.35 7.72-34.21 15.79-34.21 24.11 0 8.5 12.4 16.75 35.74 24.62-.53-16.42-1.05-32.73-1.53-48.73"
          ></path>
          <path
            fill="#131e44"
            d="M9447.89 2550h1004.91s-52.4 251.89-502.43 251.89c-450.04 0-502.48-251.89-502.48-251.89"
          ></path>
          <path
            fill="#131e44"
            d="M10125.7 3200.29s1.5-409.07-253.19-430.54c-254.65-21.48-1118.33 61.55-1118.33 61.55l204.88-1271.38-277.09-39.18s-360.67 1472.14-304.7 1620.75c51.17 135.88 982.68 70.38 982.68 70.38s625.43-233.48 765.75-11.58"
          ></path>
          <path
            fill="#1e2d56"
            d="M10139 3218.21s-5.4-524.38-338.46-548.61c-254.87-18.54-1149.04 71.45-1149.04 71.45L8509.84 1488.1l-301.51-4.31 149.35 1559.76 1002.27 168.32s638.69-215.58 779.05 6.34"
          ></path>
          <path
            fill="#f9b39d"
            d="M9113.62 5438.63c-44.51-167.78-98.69-217.14-191.57-230.28-61.38-8.67 91.11-57.06 91.11-57.06s22.83-250.75 168.5-250.75l80.92-230.54h261.86l-185.61 468.62s127.78 63.15 98.06 150.39c-50.38 147.83-275.45 329.87-323.27 149.62"
          ></path>
          <path
            fill="#131e44"
            d="M9011.07 5633.41s-35.67-108.65 0-211.55c35.68-102.9 142.7-200.05 188.48-161.33 146.07 123.48 182.69-10.32 98.81-102.36-83.87-92.05 24.83-228.17 24.83-228.17h117.43s168.62 260.37 168.62 427.78c0 332.17-269.08 292.78-598.17 275.63"
          ></path>
          <path
            fill="#838eb2"
            d="M8995.22 5522.62c.02 54.15 11.87 97.53 15.07 108.24-3.2-10.71-15.05-54.09-15.07-108.24"
          ></path>
          <path
            fill="#9d746f"
            d="M9176.72 5251.88c8.6.39 16.33 3.15 22.83 8.65-6.5-5.5-14.23-8.26-22.83-8.65m165.76 1.2c1.26 32.56-13.86 56.83-44.81 56.83-23.41 0-55.87-13.88-97.14-48.56 41.64 34.99 74.33 48.99 97.8 48.99 31.03 0 45.95-24.48 44.15-57.26"
          ></path>
          <path
            fill="#0c1430"
            d="M9342.21 5248.53c.12 1.53.22 3.05.27 4.55 1.8 32.78-13.12 57.26-44.15 57.26-23.47 0-56.16-14-97.8-48.99-.32-.27-.65-.55-.98-.82-6.5-5.5-14.23-8.26-22.83-8.65-.71-.03-1.43-.05-2.16-.05-52.02 0-133.3 82.96-163.49 170.03-11.91 34.37-15.87 69.37-15.85 100.76.02 54.15 11.87 97.53 15.07 108.24.49 1.67.78 2.55.78 2.55l.52.03c-7.38-40.82-13.13-107.73 9.28-172.4 30.19-87.08 111.47-170.04 163.49-170.03 9.45 0 17.95 2.74 24.98 8.69 41.75 35.29 74.56 49.4 98.16 49.4 43.81 0 55.92-48.62 34.71-100.57"
          ></path>
          <path
            fill="#9d746f"
            d="M9421.46 4930h-98.27 98.27m-154.65 152.9c.63 15.14 3.68 30.3 10.12 44.91-.08-.86-.14-1.72-.19-2.58-6.08-13.81-9.12-28.09-9.93-42.33"
          ></path>
          <path
            fill="#0c1430"
            d="M9440.62 4930h-117.43s-59.55 76.09-56.38 152.9c.81 14.24 3.85 28.52 9.93 42.33-4.93-77.76 56.25-155.23 56.25-155.23h117.42s106.8 165.93 150.38 318.23c-34.03-161.38-160.17-358.23-160.17-358.23"
          ></path>
          <path
            fill="#56678c"
            d="M8250.39 4165.17l148.66 183.77s474.14-495.56 497.31-453.36c104.35 190.13 35.35 738.51 200.49 810.93 165.15 72.42 473.22 98.54 619.54-45.11 146.32-143.64 603.21-765.82 509.81-956.31-25.4-52.02-153.3 0-153.3 0s123.6-607.58 29.8-640.51c-496.5-174.31-775.35 122.96-775.35 122.96l-120.82 683.43s-99.08-431.82-261.9-416.71c-162.82 15.12-694.24 710.91-694.24 710.91"
          ></path>
          <path
            fill="#f9b39d"
            d="M8399.05 4348.94s-148.02 208.84-264.14 208.84c-60.3 0 154.48-124.2 105.29-151.72-53.17-29.75-306.11 130.4-315.92 52.59-9.81-77.81 326.11-293.48 326.11-293.48l148.66 183.77"
          ></path>
          <path
            fill="#131e44"
            d="M9240.93 1370l-30.66.54 277.44 1209.18c-39.45 95.64-275.76 828.77-105.74 1090.79 41.88 64.54 104.76 99.49 186.88 99.49h.04c209.84 0 388.53-196.82 531.11-580.5 105-282.37 150.4-569.59 150.9-572.46l-29.7-2.96c-1.7 11.25-181.4 1125.92-652.31 1125.92h-.04c-72.32 0-125.21-29.57-161.71-85.83-166.41-256.43 78.21-994.64 109.99-1065.83l2.04-5.13-1.12-5.16L9240.93 1370"
          ></path>
          <path
            fill="#131e44"
            d="M9583.46 3077.65c-139.16 0-212.09 31.1-216.08 32.85l12.04 27.48c1.22-.53 125.25-52.79 357.74-18.5 265.74 39.2 337.04 80.46 337.74 80.88l15.6-25.58c-2.9-1.8-74.4-44.48-348.97-84.98-59.72-8.81-112.47-12.15-158.07-12.15"
          ></path>
          <path
            fill="#131e44"
            d="M9490.3 3257.65c-40.67 0-83.4.81-127.62 2.43-10.83.4-22.68.68-22.68.68v30s12.42-.29 23.78-.7c299.73-11 515.85 15.38 641.92 78.44l13.5-26.84c-111.68-55.85-289.23-84.01-528.9-84.01"
          ></path>
          <path
            fill="#131e44"
            d="M10037.4 1364.27l-30.7.31 217.2 1208.55 29.6-6.07-216.1-1202.79"
          ></path>
          <path
            fill="#131e44"
            d="M9735 1370l-143.69 1178.98 29.78 3.05L9764.8 1370H9735"
          ></path>
          <path
            fill="#131e44"
            d="M10458.2 1370l-143.7 1180.78 29.7 3.96L10488 1370h-29.8"
          ></path>
          <path
            fill="#b4bbd1"
            d="M10186 3682.01l-1.5.31c.3.03.7.07 1.1.1.3-.27.4-.41.4-.41"
          ></path>
          <path
            fill="#3f4f73"
            d="M10184.5 3682.32l-111.6 22.77c-50.1 156.83-199.37 604.02-196.02 604.02.19 0 .89-1.49 2.15-4.6 196.77-487.54 299.87-614.15 306.57-622.09-.4-.03-.8-.07-1.1-.1"
          ></path>
          <path
            fill="#3f4f73"
            d="M9265.11 3595.02l-49.82 275.63 72.83 190.05-23.01-465.68"
          ></path>
          <path
            fill="#f79e85"
            d="M9215.97 4803.66l-34.31 97.48 44.43 10.01-10.12-107.49"
          ></path>
          <path
            fill="#f79e85"
            d="M9261.71 5205.02c-4.06 0-7.59 1.42-9.7 4.28-4.4 5.99-.93 16.04 7.76 22.44 4.54 3.34 9.6 5.02 14.03 5.02 4.06 0 7.59-1.42 9.69-4.28 4.42-5.99.94-16.03-7.75-22.43-4.54-3.34-9.6-5.03-14.03-5.03"
          ></path>
          <path
            fill="#000"
            d="M9069.38 5242.32c0-11.83 4.39-21.43 9.8-21.43 5.41 0 9.79 9.6 9.79 21.43s-4.38 21.42-9.79 21.42c-5.41 0-9.8-9.59-9.8-21.42"
          ></path>
          <path
            fill="#000"
            d="M9064.01 5034.02c-11.6 0-23.97 8.71-36.82 25.95l8.01 5.98c11.65-15.63 22.27-23.2 30.89-21.76 9.89 1.59 16.44 15.22 18.14 20.33l9.5-3.11c-.32-.99-8.1-24.16-26-27.09-1.23-.2-2.47-.3-3.72-.3"
          ></path>
          <path
            fill="#141e3f"
            d="M9795.05 2940.03c-2.86 0-5.72.02-8.63.05-147.28 1.69-294.74 134.91-298.8 138.6.34-.15.67-.31 1-.47 3.37-1.69 14.53-7.15 32.32-14.32 54.38-22.45 119.73-41.38 195.56-49.65 30.23-3.73 62.73-6 97.03-6 81.52 0 173.22 12.84 269.07 49.48.2.08.5.16.7.24.3.08.6.12.9.12 7.2 0-4.9-29.51-48.9-59.02-44.01-29.51-119.93-59.03-240.25-59.03"
          ></path>
          <path
            fill="#384567"
            d="M9716.5 3014.24c-75.83 8.27-141.18 27.2-195.56 49.65 38.27-15.41 107.26-38.74 195.56-49.65m366.1 43.48c0 .01 0 .02.1.04.2.08.4.15.6.2-.2-.08-.5-.16-.7-.24m-593.98 20.49c-.33.16-.66.32-1 .47l-.08.08s.36-.19 1.08-.55"
          ></path>
          <path
            fill="#60443d"
            d="M8509.83 1370.54l172.14 150.2 277.09 21.3 41.83-147.04-491.06-24.46"
          ></path>
          <path
            fill="#60443d"
            d="M8019.83 1350.54l172.14 150.2 277.09 21.3 41.83-147.04-491.06-24.46"
          ></path>
          <path
            fill="#e3ecff"
            d="M3775.17 3330.7l-185.59 1003.19 867.71-9.38-144.55-1004.91-537.57 11.1"
          ></path>
          <path
            fill="#d0dcfc"
            d="M3809.31 5478.93s-536.64 2113.14-183.18 3158.53c68.49 202.61 542.05-2727.59 234.37-3175.49l59.69-1128.08-59.69-4.04-51.19 1149.08"
          ></path>
          <path
            fill="#d0dcfc"
            d="M3963.92 4331.37v954.62s180.31 2244.85 241.45 2038.15c274.43-927.78-170.86-2034.63-181.19-2034.63-20.64 0-21.21-958.14-21.21-958.14h-39.05"
          ></path>
          <path
            fill="#d0dcfc"
            d="M4127.61 4333.89l74.07 736.94s261.95 1770.08 679.62 1933.81c0 0-263.38-1854.84-630.51-1973.28l-62.3-707.03-60.88 9.56"
          ></path>
          <path
            fill="#131e44"
            d="M14319.7 2490h-1005s52.5 251.89 502.5 251.89 502.5-251.89 502.5-251.89"
          ></path>
          <path
            fill="#b5a2a4"
            d="M5594.74 5366.42c-14.87 13.72-27.81 37.01-39.95 64.92 19.2 48.38 18.34 117.9-47.65 246.99-3.71 7.26-8.39 14.29-13.92 20.97 13.94-11.7 25.02-25.06 32.33-39.38 74.55-145.83 89.99-237.07 69.19-293.5"
          ></path>
          <path
            fill="#b5a2a4"
            d="M5653.91 4945.48c-51.23 0-101.74 22.97-101.74 22.97s.8 1.14 2.19 3.46c12.7-2.6 41.5-7.7 72.88-7.83 2.75-.13 5.52-.19 8.28-.19 9.54 0 19.09.79 28.36 2.68 20.34 3.12 39.57 9.55 53.98 21.32 11.54 9.44 16.85 25.57 17.97 45.33 10.19 33.31.15 63.57-11.31 84.43-.9 3.54-1.84 7.07-2.81 10.59.33-.04.49-.04.49-.04s69.29-69.96 16.29-144.89c-20.46-28.92-52.66-37.83-84.58-37.83"
          ></path>
          <path
            fill="#ddddfc"
            d="M13314.6 1262.39c-787.1 0-1425.2 43.94-1425.2 98.15 0 54.2 638.1 98.14 1425.2 98.14s1425.2-43.94 1425.2-98.14c0-54.21-638.1-98.15-1425.2-98.15"
          ></path>
          <path
            fill="#131e44"
            d="M1289.37 2550h1004.94s-52.43 251.89-502.47 251.89S1289.37 2550 1289.37 2550"
          ></path>
          <path
            fill="#ddddfc"
            d="M11836.7 350c-482.3 0-873.3 20.148-873.3 45 0 24.859 391 45 873.3 45 482.3 0 873.3-20.141 873.3-45 0-24.852-391-45-873.3-45"
          ></path>
          <path
            fill="#8e372e"
            d="M12970 1471.77V1280h-765.7l411.2 183.06 354.5 8.71"
          ></path>
          <path
            fill="#808cff"
            d="M1343.46 3143.2s81.65-378.43 392.29-446.96c202.68-44.7 840.12 130.53 840.12 130.53l9.78 564.3-573.51-140.02S1562 3073 1343.46 3143.2"
          ></path>
          <path
            fill="#f9917d"
            d="M2585.65 3391.07s396.69 167.79 495.64 76.43c189.68-175.14-651.18-1708.09-651.18-1708.09l-259.29 32.3 568.43 1034.37-163.38.69 9.78 564.3"
          ></path>
          <path
            fill="#ddddfc"
            d="M1955.07 1262.39c-621.99 0-1126.218 43.94-1126.218 98.15 0 54.2 504.228 98.14 1126.218 98.14 73.35 0 145.02-.61 214.46-1.77 21.01-60.89 52.92-100.54 78-109.71 15.15-5.54 27.32-8.28 37.3-8.28 37.64 0 43.9 39.02 59.39 113.75 54.13-1.74 106.28-3.83 156.14-6.24 47.27-76.1 106.46-116.62 139.63-116.62 68.98 0 69.45 29.54 58.68 104.44 234.57-17.99 382.62-44.35 382.62-73.71 0-54.21-504.22-98.15-1126.22-98.15"
          ></path>
          <path
            fill="#471e15"
            d="M2112.65 1711.55c-21.13 15.1-38.32 38.88-38.32 38.88s166.17 357.36 288.36 370.29c0 0 250.99-99.67 212.75-152.83-322.89-448.79-175.59-676.39-327.91-620.69-57.49 21.02-150.82 202.04-66.64 432.19 21.95 60.01-68.24-67.84-68.24-67.84"
          ></path>
          <path
            fill="#35140d"
            d="M2147.32 1593.31l33.57 186.08 97.86 115.13-131.43-301.21"
          ></path>
          <path
            fill="#f9b39d"
            d="M2583.63 3274.22s407.17 133.79 506.12 42.44c189.68-175.13-483.71-1574.15-483.71-1574.15l-224.48 8.8 320.37 1084.56-126.06-9.1 7.76 447.45"
          ></path>
          <path
            fill="#6673ff"
            d="M1534.09 2867.98c-61.64 0-101.15 61.15-103.42 64.76 20.15-16.18 44.74-23.33 72.37-23.33 105.45 0 255.25 104.13 372.37 207.77-17.27-16.33-228.5-215.04-307.75-243.21-11.8-4.19-23.01-5.99-33.57-5.99"
          ></path>
          <path
            fill="#d5dbef"
            d="M1902.83 4238.67l283.1 451.03 311.54-191.54-260.25-436.39-334.39 176.9"
          ></path>
          <path
            fill="#60443d"
            d="M1531.41 5443.95s-142.01 283.89 64.85 285.63c230.11 1.94 111.04-265.06 111.04-265.06l-175.89-20.57"
          ></path>
          <path
            fill="#b8adb1"
            d="M1712.88 5473.98c-.52.1-1.06.2-1.58.3 8.23 21.09 26.32 73.2 26.79 124.89.29-50.11-16.26-101.25-25.21-125.19"
          ></path>
          <path
            fill="#48302b"
            d="M1711.3 5474.28c-8.15 1.54-16.38 2.64-24.7 3.28 21.02 57.66 72.63 238.07-100.05 248.12 3.22.18 6.3 1.61 9.71 1.64.83.01 1.64.01 2.46.01 110.29 0 138.99-63.21 139.37-128.16-.47-51.69-18.56-103.8-26.79-124.89"
          ></path>
          <path
            fill="#f9b39d"
            d="M1156.31 4795.77s437.87 588.96 605.39 538.71c167.52-50.27 196.05-164.09 196.05-164.09s-123-193.69-131.38-243.95c-8.37-50.25-20.56-138.04-20.56-138.04l-50.01 51.98-104.94-115.37s-109.74-15.01-130.85 93.29l-18.1 58.47-59.82-79.15c-65.99 1.39-204.88 9.93-285.78-1.85"
          ></path>
          <path
            fill="#60443d"
            d="M1342.91 5225.59s-108.89-251.28 0-242.9c108.88 8.37 220.87 56.49 220.87 56.49s31.54 148.85 98.55 132.1c67-16.75 47.45-113.63 47.45-113.63s192.13-2.32 242.39 98.18c50.25 100.51-108.02 330.53-292.29 322.16-184.26-8.37-250.78-92.05-316.97-252.4"
          ></path>
          <path
            fill="#bb7f6d"
            d="M1340.28 4982.54h.04-.04m.32.01c.76.04 1.53.08 2.31.14 108.88 8.37 220.87 56.49 220.87 56.49s28.27 133.42 88.14 133.42c3.37 0 6.84-.43 10.41-1.32 1.41-.35 2.78-.74 4.12-1.16.02-.03.05-.06.07-.09-1.38.44-2.74.89-4.19 1.25-3.57.89-7.04 1.32-10.41 1.32-59.87 0-88.14-133.42-88.14-133.42s-111.99-48.12-220.87-56.49c-.78-.06-1.55-.1-2.31-.14"
          ></path>
          <path
            fill="#48302b"
            d="M1337.74 4982.49c-17.2 0-28.31 7.19-34.97 19.06 2.64-.37 5.39-.61 8.34-.61 1.35 0 2.74.05 4.18.16 108.89 8.37 181.4 38.6 215.13 85.64 19.55 27.28 34.56 105.01 88.44 105.01 4.94 0 10.22-.65 15.85-2.06 14.09-3.52 24.32-10.61 31.74-19.57-1.34.42-2.71.81-4.12 1.16-3.57.89-7.04 1.32-10.41 1.32-59.87 0-88.14-133.42-88.14-133.42s-111.99-48.12-220.87-56.49c-.78-.06-1.55-.1-2.31-.14-.09 0-.19-.01-.28-.01h-.04c-.86-.04-1.71-.05-2.54-.05"
          ></path>
          <path
            fill="#bb7f6d"
            d="M1709.78 5057.66s0 .01.01.01c-.01 0-.01-.01-.01-.01m.01.02c.1.52 1.6 8.27 2.29 19.53h.01c-.69-11.26-2.2-19.01-2.3-19.53"
          ></path>
          <path
            fill="#b8adb1"
            d="M1952.35 5156.21c.02.03.04.07.06.11-.02-.04-.04-.08-.06-.11m.23.45c0 .01.01.03.02.04-.01-.01-.02-.02-.02-.04m.2.42c.02.04.03.07.05.11-.02-.04-.03-.07-.05-.11m.2.43c.01.02.03.05.03.07-.01-.03-.02-.05-.03-.07m.21.45c.02.03.03.05.04.09-.01-.03-.02-.06-.04-.09m.2.42c.01.03.02.05.03.08-.01-.03-.02-.05-.03-.08m.2.45c.01.04.03.08.05.12-.02-.05-.03-.08-.05-.12m.19.42c.01.03.02.06.04.09-.02-.03-.03-.06-.04-.09m.2.46c.01.04.03.09.05.12-.02-.04-.03-.08-.05-.12m.18.42c.01.04.03.07.04.1-.01-.03-.03-.06-.04-.1m.2.48c.02.04.03.08.05.12-.02-.04-.03-.08-.05-.12m.17.41c.02.04.03.07.05.11-.02-.03-.03-.08-.05-.11m.2.48l.05.13c-.02-.04-.03-.09-.05-.13m.16.41c.02.04.04.09.06.13-.02-.04-.04-.08-.06-.13m.2.5c.02.04.03.08.05.13-.02-.04-.03-.09-.05-.13m.16.41c.02.04.03.08.05.13-.02-.05-.03-.09-.05-.13m.19.5c.02.04.03.08.05.13-.02-.04-.03-.09-.05-.13m.16.41c.01.05.03.09.04.13-.01-.04-.03-.09-.04-.13m.18.51c.02.04.03.07.04.11-.01-.03-.02-.07-.04-.11m.15.41c.02.04.03.09.05.13-.02-.04-.03-.08-.05-.13m.19.52l.03.09-.03-.09m.14.42c.02.04.03.08.04.12a.564.564 0 00-.04-.12m.17.5c.02.04.03.08.04.12a.564.564 0 00-.04-.12m.14.41c.02.05.03.1.05.15-.02-.05-.03-.1-.05-.15m.17.52c.02.04.03.09.05.13l-.05-.13m.14.42c.01.05.03.09.04.14a.737.737 0 00-.04-.14m.17.53l.03.1c-.01-.04-.02-.06-.03-.1m.13.42c.01.04.02.08.04.13-.01-.04-.03-.09-.04-.13m.16.52c.01.04.02.07.03.1-.01-.03-.02-.06-.03-.1m.12.42c.02.05.03.1.04.14-.01-.04-.02-.09-.04-.14m.16.53l.03.11-.03-.11m.12.43c.01.04.03.08.04.13-.01-.04-.03-.09-.04-.13m.15.54c.01.02.01.04.02.06-.01-.02-.01-.04-.02-.06m.12.43l.03.12-.03-.12m.51 1.96c.01.02.01.04.02.07-.01-.02-.01-.05-.02-.07m.24.98c0 .02.01.03.01.05 0-.02-.01-.03-.01-.05m.23 1v.01-.01"
          ></path>
          <path
            fill="#48302b"
            d="M1710.88 5057.64c-.72 0-1.1.01-1.1.01v.01s0 .01.01.01v.01c.1.52 1.61 8.27 2.3 19.53 56.03 3.93 174.57 21.24 212.47 97.03 33.65 67.3-26.27 192.6-124.82 265.59 105.2-62.67 176.63-187.75 159.25-264.62v-.01c-.07-.31-.14-.63-.22-.95 0-.02-.01-.03-.01-.05-.07-.31-.15-.61-.22-.91-.01-.03-.01-.05-.02-.07-.07-.3-.15-.59-.23-.89 0-.03-.01-.07-.02-.1-.07-.28-.15-.57-.23-.85l-.03-.12c-.03-.13-.07-.25-.1-.37-.01-.02-.01-.04-.02-.06-.03-.13-.07-.27-.11-.41-.01-.05-.03-.09-.04-.13l-.09-.32-.03-.11c-.04-.12-.08-.26-.12-.39-.01-.04-.02-.09-.04-.14-.03-.1-.06-.21-.09-.32-.01-.03-.02-.06-.03-.1-.04-.12-.08-.26-.12-.39-.02-.05-.03-.09-.04-.13-.04-.11-.07-.22-.1-.32l-.03-.1c-.04-.13-.09-.26-.13-.39-.01-.05-.03-.09-.04-.14-.03-.09-.06-.19-.09-.29-.02-.04-.03-.09-.05-.13-.04-.12-.08-.25-.12-.37-.02-.05-.03-.1-.05-.15-.03-.1-.06-.19-.1-.29a.564.564 0 00-.04-.12c-.04-.13-.08-.25-.13-.38a.564.564 0 00-.04-.12c-.04-.11-.07-.22-.11-.33l-.03-.09c-.05-.13-.1-.26-.14-.39-.02-.04-.03-.09-.05-.13-.03-.1-.07-.2-.11-.3a.487.487 0 00-.04-.11c-.04-.13-.09-.25-.14-.38-.01-.04-.03-.08-.04-.13-.04-.09-.07-.19-.11-.28-.02-.05-.03-.09-.05-.13-.05-.13-.09-.25-.14-.37-.02-.05-.03-.09-.05-.13-.04-.09-.07-.19-.11-.28-.02-.05-.03-.09-.05-.13-.05-.12-.1-.25-.14-.37-.02-.04-.04-.09-.06-.13-.03-.09-.07-.19-.11-.28l-.05-.13c-.05-.12-.1-.24-.15-.37-.02-.04-.03-.07-.05-.11-.04-.1-.08-.19-.12-.29-.02-.04-.03-.08-.05-.12-.05-.13-.1-.25-.16-.38-.01-.03-.03-.06-.04-.1-.04-.1-.09-.19-.13-.3a.433.433 0 01-.05-.12c-.05-.12-.11-.25-.16-.37-.02-.03-.03-.06-.04-.09-.05-.1-.09-.2-.14-.3-.02-.04-.04-.08-.05-.12-.06-.13-.11-.25-.17-.37-.01-.03-.02-.05-.03-.08-.05-.11-.11-.22-.16-.33a.247.247 0 00-.04-.09c-.06-.13-.12-.26-.18-.38 0-.02-.02-.05-.03-.07-.05-.11-.1-.21-.15-.32-.02-.04-.03-.07-.05-.11-.06-.12-.12-.25-.18-.38a.076.076 0 01-.02-.04c-.06-.12-.12-.23-.17-.34-.02-.04-.04-.08-.06-.11-.06-.13-.12-.25-.18-.38-48.04-96.07-225.71-98.19-241.29-98.19"
          ></path>
          <path
            fill="#f2785f"
            d="M948.418 3897.88l169.202 14.94s187.85-651.44 269.75-911.09c81.89-259.65 489.16 94.63 624.77 251.84l-74.33 246.72c0 40.58 52.22 355.66 91.08 511.92 0 0-36.92 585.36-410.04 725.59-444.3 166.97-543.62 124.88-652.498-143.14-108.887-268.03-17.934-696.78-17.934-696.78"
          ></path>
          <path
            fill="#f9b39d"
            d="M948.418 3897.88s158.162-573.4 217.772-584.99c59.61-11.57 112.98 46.74 112.98 46.74l-161.55 553.19-169.202-14.94"
          ></path>
          <path
            fill="#131e44"
            d="M1543.29 3130.43c-219.2-25.84-336.66-8.38-368.08-2.27-10.81 44.9-20.32 90.25-27.89 135.02 105.71-.45 540.32-1.44 683.64 70.3 5.04 2.52 9.66 6.61 13.05 10.8 21.89-48.15 42.05-97.66 60.48-147.19-31.98-11.22-129.35-39.31-361.2-66.66zm-360.07 560.88c43.23 64.7 114.22 96.08 210.68 93.6 188.2-5.05 332.41-191.51 437.92-414.33-15.65-13.66-86.32-53.86-368.02-68.56-139.92-7.3-279.59-8.53-321.27-8.75-23.68 158.34-20.53 306.41 40.69 398.04zm909.09-1075.24c-.48 2.95-50.02 298.09-158.92 590.99-147.88 397.7-329.12 602.21-538.68 607.83-107.73 2.89-187.11-33.07-236.44-106.92-191.711-286.98 138.4-1054.56 170.9-1128.37l-286-1246.42 30.79.04 286.68 1249.43-2.04 4.58c-1.98 4.43-108.14 244.72-175.45 508.96 26-4.22 73.15-9.64 144.1-9.64 57.27 0 130.02 3.54 219.56 14.1 232.86 27.46 332.83 55.92 367.97 68.28 100.77-280.52 147.01-552.15 147.93-557.77l29.6 4.91"
          ></path>
          <path
            fill="#131e44"
            d="M1875 1330h-32.92l229.89 1255.28 29.51-6.04L1875 1330"
          ></path>
          <path
            fill="#131e44"
            d="M1582.5 1330l-149.72 1220.64 29.79 3.88L1609.17 1330h-26.67"
          ></path>
          <path
            fill="#131e44"
            d="M2299.17 1330l-143.24 1218.56 29.79 2.84L2332.5 1330h-33.33"
          ></path>
          <path
            fill="#f79e85"
            d="M1633.27 5060.38c-.57 0-1.14.09-1.68.28-5.54 1.86-6.87 12.74-2.98 24.3 3.5 10.42 10.05 17.83 15.36 17.83.58 0 1.14-.09 1.69-.27 5.53-1.86 6.86-12.73 2.97-24.29-3.5-10.43-10.05-17.85-15.36-17.85"
          ></path>
          <path
            fill="#0c0c0c"
            d="M1786.04 4996.26c-5.23-9.47-6.06-19.03-1.86-21.35 4.2-2.33 11.86 3.46 17.08 12.93 5.23 9.46 6.07 19.02 1.87 21.35-4.21 2.32-11.86-3.47-17.09-12.93"
          ></path>
          <path
            fill="#0c0c0c"
            d="M1696.95 4828.29c-2.69 0-5.14.67-7.33 2-10.02 6.1-8.78 23.38-8.6 25.33l9.96-.89c-.45-5.2.47-13.85 3.85-15.91 2.21-1.34 6.69-.07 12.26 3.47l5.38-8.44c-5.83-3.7-11.02-5.56-15.52-5.56"
          ></path>
          <path
            fill="#ed5e47"
            d="M1876.53 3587.02l-319.5 577.38 57.64 58.71 261.86-636.09"
          ></path>
          <path
            fill="#f9b39d"
            d="M1730.49 3941.77s217.24-515.05 304.83-563.31c315.74-173.99 250.41 915.21 250.41 915.21s57.04 308.36-178.08 308.36c-78.81 0 13.38-308.36 13.38-308.36l-36.69-458.93-55.45 178.73s-117.9-102.3-298.4-71.7"
          ></path>
          <path
            fill="#f99180"
            d="M1209.78 4907.09l-159.21-155.04s105.74-99.4 225.84 31.21c0 0 98.94-109.95 166.44-86.53s98.27 69.35 98.27 69.35-202.37 178.39-331.34 141.01"
          ></path>
          <path
            fill="#ed5e47"
            d="M1059.88 3907.72l-25.43 315.39 85.8-308.56-21.66-3.41-38.71-3.42"
          ></path>
          <path
            fill="#f48d75"
            d="M1060.09 3905.06l-.21 2.66 38.71 3.42-38.5-6.08"
          ></path>
          <path
            fill="#0c0c0c"
            d="M2075.43 3352.45c-3.73 0-7.64.72-11.72 2.12-127.95 44.27-440.45 833.14-453.7 866.7l9.31 3.67c3.22-8.17 324.01-818.14 447.66-860.91 6.71-2.33 12.35-2.07 17.22.79l5.05-8.64c-4.25-2.48-8.86-3.73-13.82-3.73"
          ></path>
          <path
            fill="#ddddfc"
            d="M6362.44 1262.39c-307.28 0-585.82 10.72-789.02 28.11l-51.11 135.4c206.24 20.12 506.2 32.78 840.13 32.78 401.88 0 754.59-18.34 953.9-45.95l-24.7-107.67c-202.98-25.77-543.45-42.67-929.2-42.67m-800.07 29.07c-19.82 1.74-38.9 3.55-57.18 5.42-1.29 39.82-2.64 80.74-4.06 122.63l1 4.37c3.28.33 6.55.67 9.87 1l50.37-133.42m1761.97 18l22.51 98.79c90.34-14.13 141.81-30.39 141.81-47.71 0-18.71-60.07-36.2-164.32-51.08"
          ></path>
          <path
            fill="#131e44"
            d="M5696.73 2550h1004.95s-52.44 251.89-502.47 251.89c-450.04 0-502.48-251.89-502.48-251.89"
          ></path>
          <path
            fill="#997773"
            d="M5549.75 3182.44l865.6 66.3 899.4 136.04 672.1-1936.1-296.93-.18-754.89 1343.98s-812.08-167.55-1050.37-56.32c-126.4 59-334.91 446.28-334.91 446.28"
          ></path>
          <path
            fill="#af918c"
            d="M5607.4 2939.89s484.56-59.39 767.15 305.73l992.88-56.57 401.51-1740.37-280.28 10-519.98 1237.36s-875.35-82.11-1113.64 29.12c-126.39 58.99-247.64 214.73-247.64 214.73"
          ></path>
          <path
            fill="#93a2f9"
            d="M5607.4 2939.89s479.66-61.02 767.15 305.73l-214.88 495.79s355.3-276.72 313.88 61.44c-41.42 338.17-468.67 821.31-607.47 889.3-137.15 67.19-461.18 108.76-461.18 108.76s-133.98 1358.21 0 1738.67c16.45 46.72-270.54 3.7-270.54 3.7s-345.15-1311.58 0-2291.81c345.16-980.24 473.04-1311.58 473.04-1311.58"
          ></path>
          <path
            fill="#131e44"
            d="M5479.78 1326.47l-29.24 6.71 285.99 1246.42c-32.49 73.81-362.6 841.39-170.89 1128.37 49.33 73.85 128.88 109.83 236.44 106.93 209.56-5.63 390.81-210.14 538.68-607.84 108.9-292.9 158.44-588.05 158.92-590.99l-29.6-4.91c-1.92 11.61-197.61 1161.09-668.81 1173.75-96.45 2.48-167.46-28.9-210.68-93.6-188.1-281.57 171.72-1095.89 175.38-1104.08l2.04-4.58-288.23-1256.18"
          ></path>
          <path
            fill="#131e44"
            d="M5734.62 3086.55c-112.47 0-165.27 13.64-168.51 14.5l7.76 28.98-3.88-14.49 3.86 14.49c1.15-.29 118.2-30.09 376.81.4 297.23 35.05 373.47 71.36 374.2 71.73l13.27-26.91c-3.19-1.58-81.64-38.96-383.96-74.6-89.54-10.56-162.28-14.1-219.55-14.1"
          ></path>
          <path
            fill="#131e44"
            d="M6284.94 1360h-30.48l215.66 1207.32 29.53-5.41L6284.94 1360"
          ></path>
          <path
            fill="#131e44"
            d="M5983.86 1368.63L5840.15 2550.1l29.79 3.61 143.71-1181.46-29.79-3.62"
          ></path>
          <path
            fill="#7685f9"
            d="M6269.53 3483.68l-508.6 823.8 398.74-566.07 109.86-257.73"
          ></path>
          <path
            fill="#f9b39d"
            d="M5370.97 4760s-186.97 702.76-30.5 877.68c156.47 174.92 174.89-233.63 266.93-280.28 92.04-46.65 153.53-57.72 153.53-57.72l-82.96-50.87s97.48-213.83 39.89-260.92c-52.78-43.15-170.33-14.48-170.33-14.48L5607.4 4760h-236.43"
          ></path>
          <path
            fill="#7685f9"
            d="M5243.85 4739.75l80.82 227.43c23.18-120.27 46.3-207.18 46.3-207.18h11.98l1.73-6.16-140.83-14.09"
          ></path>
          <path
            fill="#c7939d"
            d="M5382.95 4760h-11.98s-23.12 86.91-46.3 207.18l.01.01 58.27-207.19"
          ></path>
          <path
            fill="#59281b"
            d="M5722.2 5128.2s-46.29.13-179.17 179.27c73.86 41.04 92.46 137.38-17.48 352.45-59.8 116.99-369.08 171.5-467.73-239.64-66.5-277.13 258.97-451.84 258.97-451.84l115.72 71.31 4.6 136.19s-145.71 81.73-69.63 145.39c45.24 37.88 77.1-27.47 104.45-34.71 47.86-65.47 126.12-185.89 107.86-251.39-12.71-45.55-27.62-66.79-27.62-66.79s133.02-60.48 186.32 14.87c53 74.93-16.29 144.89-16.29 144.89"
          ></path>
          <path
            fill="#f9b39d"
            d="M5134.37 6540s109.49 379.65 358.85 552.06c67.42 46.61-98.68-398.97-98.68-398.97s110.79 160.03 129.61 91L5404.9 6540h-270.53"
          ></path>
          <path
            fill="#ebebfc"
            d="M5316.04 4750l36.48 90h239.94l96.04-90h-372.46"
          ></path>
          <path
            fill="#ebebfc"
            d="M5134.37 6576.16l-19.21-113.23 261.43-38.51 28.31 117.46-270.53 34.28"
          ></path>
          <path
            fill="#86635e"
            d="M6127.85 3039.77c6.63 3.63 13.25 7.37 19.88 11.24 45.23 25.49 89.87 56.49 132.35 94.14-.02-.07-.11-.38-.28-.93-48.43-42.94-99.91-77.19-151.95-104.45"
          ></path>
          <path
            fill="#997873"
            d="M5820.98 2890.27c-117.81 0-179.55 45.82-179.55 45.82 18.22-.85 36.81-1.32 55.71-1.32 132.95 0 281.58 23.32 430.71 105 52.04 27.26 103.52 61.51 151.95 104.45-5.3-16.74-85.66-249.97-453.91-253.93-1.64-.01-3.29-.02-4.91-.02"
          ></path>
          <path
            fill="#8186cc"
            d="M6147.73 3051.01c44.26 25.8 88.51 56.88 132.36 94.16 0 0 0-.01-.01-.02-42.48-37.65-87.12-68.65-132.35-94.14"
          ></path>
          <path
            fill="#152344"
            d="M7701.59 1528.47l34.69-197.08 656.93-2.54-438.77 213.19-252.85-13.57"
          ></path>
          <path
            fill="#1e2d56"
            d="M7461.53 1543.8l28.01-214.85 680.93.87-431.73 216.47-277.21-2.49"
          ></path>
          <path
            fill="#131e44"
            d="M5645.58 3260.35c-66.4 0-108.37 2.83-109.68 2.92l2.13 29.93c4.12-.28 413.9-27.79 711.65 93.36l11.31-27.79c-208.75-84.93-470.36-98.42-615.41-98.42"
          ></path>
          <path
            fill="#997873"
            d="M7034.04 2540.49l-60.6 152.29 78.35 19.58-17.75-171.87"
          ></path>
          <path
            fill="#59281b"
            d="M2388.16 1625.67c-25.03 6.93-49.34 23.36-49.34 23.36s33.32 392.68 143.64 446.8c0 0 269.95-7.39 252.29-70.46-149.1-532.38 67.42-695.56-94.76-695.56-61.22 0-211.04 137.96-211.04 383.01 0 63.9-40.79-87.15-40.79-87.15"
          ></path>
          <path
            fill="#421a11"
            d="M2461.34 1526.54l-32.39 186.28 52.37 141.74-19.98-328.02"
          ></path>
          <path
            fill="#fff"
            d="M13841.7 3307.38l-176 684.55 597.8-4.65 225.3-1.75-137.2-685.72-509.9 7.57"
          ></path>
          <path
            fill="#e3ecff"
            d="M14814.6 3297.17l-205 796.92 958.2 4.19-159.6-798.28-593.6-2.83"
          ></path>
          <path
            fill="#e9f0ff"
            d="M15281.5 7157.18c-866.8-851.34-295.1-2358.75-295.1-2358.75l1.7.02v-729.64h66.6l-1.1 765.26c301 261.87 411 2502.96 227.9 2323.11"
          ></path>
          <path
            fill="#e9f0ff"
            d="M14511 6699.66c-600.5-972.96 227.6-1786.3 227.6-1786.3l144-134.27 50.2-780.13h69.7l-58.3 787.86c15.2 661.31-298.4 2131.27-433.2 1912.84"
          ></path>
          <path
            fill="#e9f0ff"
            d="M15281.5 4679.16l-79.8-613.52 57-2.19 125.7 611.07s866.3 443.67 284.8 1486.89c0 0-298.5-1047.88-387.7-1482.25"
          ></path>
          <path
            fill="#4b6faf"
            d="M14111.5 2854.34s-368.2-51.8-634.7 280.24l199.2 448.86s-329.4-250.53-291 55.63c38.4 306.16 434.5 743.57 563.2 805.13 127.1 60.83 427.5 98.46 427.5 98.46s259.6 8.57 158.3-538.63c-171.8-927.6-422.5-1149.69-422.5-1149.69"
          ></path>
          <path
            fill="#f9b39d"
            d="M14442.4 4522.86s253.2 639.62 108.1 797.99c-145.1 158.36-277.2-231.05-362.5-273.27-85.3-42.24-142.3-52.81-142.3-52.81l76.9-46.33s-90.4-193.72-37-236.36c48.9-39.06 162.1 3.76 162.1 3.76l-70.3-205.84 265 12.86"
          ></path>
          <path
            fill="#3e5fad"
            d="M13562.4 3327.42l113.6 256.02 210.7 210.4-324.3-466.42"
          ></path>
          <path
            fill="#ad827b"
            d="M14111.5 2854.34s-341.4-63.27-620 296.66c0 0-666.2-25.3-812.1-164.62-127.8-121.99-63.2-1526.38-63.2-1526.38h354.5l-103.9 1248.04s774.1-239.63 1009-129.99c124.6 58.16 235.7 276.29 235.7 276.29"
          ></path>
          <path
            fill="#af918c"
            d="M14111.5 2854.34s-341.4-63.27-620 296.66c0 0-835 189.7-980.9 50.37-127.7-121.99-391.4-1741.37-391.4-1741.37h346.6l287.5 1345.44s795.8-310.43 1048.2-251.21c270 63.34 310 300.11 310 300.11"
          ></path>
          <path
            fill="#b6b6ce"
            d="M14049.3 2850.06c-33.6 0-79.1 3.17-131.8 14.95 55.7-10.85 103.6-13.6 137.9-13.6 35.3 0 56.1 2.93 56.1 2.93s-23.1-4.28-62.2-4.28"
          ></path>
          <path
            fill="#415c8f"
            d="M14055.4 2851.41c-34.3 0-82.2 2.75-137.9 13.6-121.7 27.18-282.2 100.2-426 285.99 187.4-239.82 400.1-297.61 579.8-297.6 13.6 0 27 .33 40.2.94 0 0-20.8-2.93-56.1-2.93"
          ></path>
          <path
            fill="#997873"
            d="M13920.1 2806.96c-10.9 0-22.1.48-33.4 1.53-368.8 33.89-395.2 342.51-395.2 342.51 206.1-266.32 446.6-300.95 557.8-300.94 39.1 0 62.2 4.28 62.2 4.28s-82.5-47.38-191.4-47.38"
          ></path>
          <path
            fill="#9b4a3a"
            d="M12465.8 1463.06l-8.7-183.06H11690l449.3 191.77 326.5-8.71"
          ></path>
          <path
            fill="#a52f0f"
            d="M14235.3 5307.19c-24.3-40.22 34.9-163.07 82.8-168.7 48-5.63 0-188.68 0-188.68l21.3-17.44s75.8 136.99 103.9 60.97c19.9-53.57-79.1-203.34-79.1-203.34l149.5-60s172.1 226.28 199.2 314.68c75.5 246.17-315.9 530.32-477.6 262.51"
          ></path>
          <path
            fill="#131e44"
            d="M14154.1 3018.67c-45.6 0-98.3 3.34-158.1 12.15-274.5 40.51-346 83.2-349 84.99l15.8 25.53c.6-.36 71.9-41.63 337.6-80.83 232.5-34.32 356.5 17.97 357.8 18.5l12-27.48c-4-1.75-76.9-32.86-216.1-32.86"
          ></path>
          <path
            fill="#131e44"
            d="M14247.3 3198.68c-239.7 0-417.3 28.17-529 84.02l13.4 26.83c126.1-63.05 343.6-89.43 643.3-78.43 11.4.41 15 .7 25 .7v-30c-10 0-13.1-.28-23.9-.69-44.2-1.62-88.2-2.43-128.8-2.43"
          ></path>
          <path
            fill="#131e44"
            d="M13699.7 1310l-215.6 1199.65 29.5 4.96 217-1204.61h-30.9"
          ></path>
          <path
            fill="#131e44"
            d="M14003 1313.21l-30.2.06 143.7 1181.48 29.8-3.62-143.3-1177.92"
          ></path>
          <path
            fill="#131e44"
            d="M13280 1310h-30.4l143.7 1183.14 29.8-4.42L13280 1310"
          ></path>
          <path
            fill="#131e44"
            d="M14456.2 1313.15l-237.7 1210.77 1.9 4.33c31.8 71.2 276.4 810.03 110 1066.44-36.5 56.25-89.4 85.31-161.7 85.31-470.9 0-650.6-1114.72-652.3-1125.97l-29.7 3.78c.5 2.87 45.9 290.26 150.9 572.63 142.6 383.68 321.2 579.56 531.1 579.56 82.1 0 145-34.43 186.9-98.98 170.2-262.21-66.7-995.05-105.8-1089.66l237.1-1208.15-30.7-.06"
          ></path>
          <path
            fill="#131e44"
            d="M12349.7 1710h-1138.4s59.4 285.34 569.2 285.34c509.8 0 569.2-285.34 569.2-285.34"
          ></path>
          <path
            fill="#131e44"
            d="M11661.8 380.402L11412.7 1746.8l29.5 5.37 250-1371.471-30.4-.297"
          ></path>
          <path
            fill="#131e44"
            d="M12523.4 380.402L12274.3 1746.8l29.5 5.37 250-1371.471-30.4-.297"
          ></path>
          <path
            fill="#131e44"
            d="M11993.5 379.781l-30.2-.019 162.8 1384.408 29.8-3.49-162.4-1380.899"
          ></path>
          <path
            fill="#131e44"
            d="M11119.1 383.172l-30.3-.293 162.8 1399.681 29.8-3.46-162.3-1395.928"
          ></path>
          <path
            fill="#131e44"
            d="M12298.2 2304.78c-53.6 0-121.7 4.07-205.5 16.28-283 41.14-356.2 84.2-359.2 86.01l11 27.45c.6-.4 74.4-42.84 351.1-83.08 242.7-35.29 352.5-.74 353.6-.41l6.4-29.56c-3-1-52.3-16.69-157.4-16.69"
          ></path>
          <path
            fill="#131e44"
            d="M12290.8 2496.92c-145.1 0-341.3 20.21-480.5 112.67l11.8 26.94c235.2-156.3 655.6-97.71 659.8-97.08l2.9-30.4c-7.5-1.08-86.8-12.13-194-12.13"
          ></path>
          <path
            fill="#131e44"
            d="M12434.5 3002.76c165-304.69-118.4-1146.46-159.4-1264.12h27.1c55.3 159.14 322.3 968.33 152.9 1281.11-45.9 84.7-120.1 126.13-220.1 122.65-195.6-6.48-364.9-242.37-503.2-701.1-101.9-338.3-148.3-679.19-148.8-682.59l24.5-5c1.8 13.44 185.4 1343.42 628.2 1358.11 91 2.91 157.9-33.67 198.8-109.06"
          ></path>
          <path
            fill="#f9b39d"
            d="M13491.5 3996.51s158.3 17.33 163.4-4.58c5.1-21.93-138.5-41.93-138.5-41.93s104.9-50.89 94.1-115c-23-137.97-170-131.85-218.2-65.98-48.2 65.86-35.8 217.26 99.2 227.49"
          ></path>
          <path
            fill="#000"
            d="M14131.6 4858.01c-21.1 0-38.7 20.45-39.4 21.36l7.6 6.47c.2-.2 16.7-19 33.5-17.76 8.4.69 16 6.44 22.4 17.11l8.6-5.18c-8.2-13.59-18.4-20.96-30.2-21.9l-2.5-.1"
          ></path>
          <path
            fill="#f79e85"
            d="M14210.1 4604.41l37.6 111.43 59 14.16-96.6-125.59"
          ></path>
          <path
            fill="#000"
            d="M14261.3 5040.04c-6.5-11.93-15.6-19.54-20.3-16.97-4.6 2.56-3.1 14.31 3.5 26.25 6.6 11.93 15.7 19.53 20.4 16.97 4.6-2.56 3-14.32-3.6-26.25"
          ></path>
          <path
            fill="#4b7abf"
            d="M14047 4478.97l157.7 111.03h265.3l18.8-106.46s-269.8-10.62-441.8-4.57"
          ></path>
          <path
            fill="#f9b39d"
            d="M11047.4 7214.23l9.3 154.38c-37.2-.2-91.5 13.88-127.5 87.1-59.1 120.42-12 409.72-12 409.72s287 80.16 323.8-13.84c36.8-94.01-6-363.98-6-363.98l2.1-246.75-189.7-26.63"
          ></path>
          <path
            fill="#1e2d56"
            d="M10901.7 7578.84l10.3 87.91s-46.4 214.43 51.5 131.73c35.6-30.05 121.8-72.73 202.3 6.88 32.6 32.27 61.4-101.55 57.6-171.49-3.4-62.87 20.4-49.77 20.4-49.77s139 232.91 70.3 343.5c-100.6 162.08-577.2 343.95-412.4-348.76"
          ></path>
          <path
            fill="#808cff"
            d="M11806 3409.77c0 152.37-164.8 2403.45-164.8 2403.45l-746.6-52.1s-5.3-1131.06-183.4-2385.07l273.1-10.48c51.4 216.48 169.6 1285.71 298.5 1958.33 163.6-667.48 200.5-1716.17 261.3-1930.5l261.9 16.37"
          ></path>
          <path
            fill="#1e2d56"
            d="M11544.1 3225.77l560.5 2.97c23.7 96.71-298.6 181.03-298.6 181.03l-261.9-16.37v-167.63"
          ></path>
          <path
            fill="#1e2d56"
            d="M10991.7 3227.4l-531.9 11.81c-20.9 89.78 251.4 136.84 251.4 136.84l278.2 5.24 2.3-153.89"
          ></path>
          <path
            fill="#f9b39d"
            d="M11764.5 5528.4s-53.3-196.03-16.1-166.31c40.8 32.52 37.1-77.09 37.1-77.09s54.2-140.57 130.6-13.59c15.1 25.22 28.1 238.33 28.1 238.33l-179.7 18.66"
          ></path>
          <path
            fill="#000"
            d="M10966.2 7662.27c.1-11.81-2.7-21.39-6.2-21.4-3.4 0-6.2 9.57-6.2 21.38-.1 11.82 2.7 21.4 6.2 21.4 3.4.01 6.2-9.56 6.2-21.38"
          ></path>
          <path
            fill="#000"
            d="M11142.7 7652.54c0-11.82-2.8-21.4-6.2-21.41-3.5 0-6.3 9.57-6.3 21.39 0 11.81 2.7 21.39 6.2 21.4 3.4.01 6.3-9.57 6.3-21.38"
          ></path>
          <path
            fill="#000"
            d="M11209 7673.92l-9.1 5.19c8.6 15.12 52.7 90.76 79.6 88.93 4.3-.25 10.3-2.47 14.2-11.56l-9.7-4.08c-2.1 5-4.3 5.14-5.1 5.18-15 1.09-49.8-48.39-69.9-83.66"
          ></path>
          <path
            fill="#3d50a0"
            d="M9928.39 7294.1l173.11 110.51s202.5-304.22 251.8-345.45c48.9-40.83 324.1 111.81 652.5 183.32 184.9 40.25 438.8 5.13 576.7-94.94 178.1-129.21 289.8-642.75 315.8-841.63 58.5-448.67 70.1-817.18 70.1-817.18l-212.2 40.2c0 125.72-76 764.37-76 764.37s-182.8 534.4-181.9 525.56c56.4-524.02 201.6-1219.15 201.6-1219.15l-377.1-24.51-47 201.89-20.3-206.71-431.3-19.44c29.5 326.93-58.8 1291.08-58.8 1291.08s-330.1-86.82-436.6-75.14c-167.6 18.4-400.41 527.22-400.41 527.22"
          ></path>
          <path
            fill="#fff"
            d="M11005.8 7242.48l28.2 70.88 109.4-27.11 103 50.39 61.8-96.99-67.9-787.07-234.5 789.9"
          ></path>
          <path
            fill="#e0624c"
            d="M11141.5 6785.28l2.6 369.44-35.3 67 36.2 64.74 51.3-57.34-16.1-58.15 91.7-352.11-30-392.47-100.4 358.89"
          ></path>
          <path
            fill="#d0dcfc"
            d="M11283 6260.35c0-14.39-10.9-26.06-24.4-26.06-13.4 0-24.3 11.67-24.3 26.06 0 14.39 10.9 26.05 24.3 26.05 13.5 0 24.4-11.66 24.4-26.05"
          ></path>
          <path fill="#cec2d5" d="M11054.4 7330.54l2.3 38.07-2.3-38.07"></path>
          <path
            fill="#f79e85"
            d="M11056 7330.53c-.5 0-1.1 0-1.6.01l2.3 38.07s49.7 9.51 106 52.19c2.4 1.83 4.2 2.67 5.5 2.67 7 0-1.2-23.23-21-46.47s-51.3-46.47-91.2-46.47"
          ></path>
          <path
            fill="#000"
            d="M11044 7438.38c-1 0-2 .07-3 .22-16.3 2.31-23.7 22.73-24 23.6l9.9 3.51c1.5-4.26 7.3-15.59 15.6-16.75 7.2-.99 16.3 5.67 26.2 19.36l8.5-6.12c-11.4-15.82-22.6-23.82-33.2-23.82"
          ></path>
          <path
            fill="#000"
            d="M11037.4 7542.33l34 3.69 1.2-10.41-47.1-5.12 11.2 85.64 10.4-1.24"
          ></path>
          <path
            fill="#f9b39d"
            d="M11252.9 7649.31c6.3 9.6 47.7 27.72 45.7-3.98-6.3-99.66-54.8-74.78-54.8-74.78s-1.8 62.18 9.1 78.76"
          ></path>
          <path
            fill="#f9b39d"
            d="M10890 7683.65s-39.7 24.53-37.3-7.14c6.6-88.32 48.1-73.51 48.1-73.51l-10.8 80.65"
          ></path>
          <path
            fill="#000"
            d="M10949.2 7730.13c-9.2 0-17.9-2.02-26-6.01-15.2-7.44-26.3-20.88-31.3-37.85-5.1-17.13-3.4-35.77 4.8-52.49 12.1-24.52 36.3-40.36 61.7-40.36 9.1 0 17.9 2.03 26 6.02 31.4 15.43 43.3 55.96 26.4 90.34-12 24.52-36.2 40.35-61.6 40.35zm9.2-147.18c-29.4 0-57.3 18.14-71.1 46.21-9.3 19.09-11.3 40.43-5.5 60.08 5.9 19.81 18.9 35.54 36.7 44.29 9.6 4.7 19.9 7.08 30.7 7.08 29.3 0 57.2-18.14 71-46.21 19.4-39.56 5.4-86.39-31.2-104.36-9.6-4.71-19.9-7.09-30.6-7.09"
          ></path>
          <path
            fill="#000"
            d="M11138.1 7714.56c-9.2 0-17.9-2.02-26-6.01-31.5-15.45-43.3-55.97-26.5-90.35 12.1-24.51 36.3-40.35 61.7-40.35 9.1 0 17.8 2.02 26 6.01 31.4 15.44 43.3 55.97 26.4 90.35-12 24.51-36.2 40.35-61.6 40.35zm9.2-147.19c-29.4 0-57.3 18.14-71.1 46.22-19.4 39.56-5.4 86.37 31.2 104.36 9.6 4.7 19.9 7.09 30.7 7.09 29.3 0 57.2-18.14 71-46.22 19.4-39.56 5.4-86.38-31.2-104.36-9.6-4.7-19.9-7.09-30.6-7.09"
          ></path>
          <path
            fill="#000"
            d="M9956.64 7497.02l52.66 22.3s-478.97 686.91-602.31 838.78c-110.47 136.03 549.65-861.08 549.65-861.08"
          ></path>
          <path
            fill="#f9b39d"
            d="M10088.3 7402.51s-57.1 202.76-98.64 218.29c-41.57 15.53 0-109.14 0-109.14s-41.71 99.21-151.52 77.66c-49.44-9.71-56.55-136.27 109.7-287.75l140.46 100.94"
          ></path>
          <path
            fill="#f79e85"
            d="M9962.16 7379l26.19 84.59s0-11.42-1.74-25.99c-2.56-21.43-8.87-49.7-24.45-58.6"
          ></path>
          <path
            fill="#6673ff"
            d="M10842.2 3378.52l237.4 2183.94h.1l12.6.56-239.5-2184.3-10.6-.2"
          ></path>
          <path
            fill="#182556"
            d="M10852.4 3375.48l-10.4 1.14.2 1.9 10.6.2-.4-3.24"
          ></path>
          <path
            fill="#6673ff"
            d="M11714 3404.02l-228.7 2181.75 6.5.41 4 .27 228.7-2181.78-10.5-.65"
          ></path>
          <path
            fill="#182556"
            d="M11715.4 3390.39l-1.4 13.63 10.5.65 1.4-13.19-10.5-1.09"
          ></path>
          <path
            fill="#fca38b"
            d="M8483.7 3313.8s217.93 273.95 364.18 237.82C9152.48 3476.4 8554.03 1100 8554.03 1100h-262.65s0 1690.43 192.32 2213.8"
          ></path>
          <path
            fill="#131e44"
            d="M6707.5 1360l-144.2 1187.6 29.79 2.36L6736.8 1360h-29.3"
          ></path>
          <path
            fill="#ddddfc"
            d="M8509.03 358.582c-900.11 0-1629.79 43.938-1629.79 98.137 0 18.359 83.65 35.531 229.27 50.23l-10.01-43.648 32.53-2.321 11.21 49.231c290.71 26.891 794.28 44.66 1366.79 44.66 900.11 0 1629.77-43.941 1629.77-98.152 0-54.199-729.66-98.137-1629.77-98.137"
          ></path>
          <path
            fill="#4f3631"
            d="M8290 1310h327.28s-61.36-475.711-23.57-524.539c92.6-119.66 382.26-192.172 341.46-322.5l-466.98.43-61.71 202.519-31.72-202.5-97.62-.449 8.71 847.039"
          ></path>
          <path
            fill="#f9b39d"
            d="M7386.74 4514.58s292.81-185.88 328.61 215.54c3.29 36.97 105.91 33.4 92.76 62.57-18.48 40.94-122.11 115.45-122.11 115.45l-99.93 235.08s-537.82-343.17-199.33-628.64"
          ></path>
          <path
            fill="#000"
            d="M7667.17 5016.27c-11.57 0-27.72-36.19-27.72-95.13 0-58.93 16.15-95.11 27.72-95.11s27.71 36.18 27.71 95.11c0 58.94-16.14 95.13-27.71 95.13zm0-200.24c-24.5 0-37.72 54.16-37.72 105.11 0 50.97 13.22 105.13 37.72 105.13s37.71-54.16 37.71-105.13c0-50.95-13.21-105.11-37.71-105.11"
          ></path>
          <path
            fill="#fff"
            d="M7501.86 4359.1s3.59 92.04 97.08 90.88c34.4-.43 0-137.94 0-137.94s82.93 85.53 100.94 73.76c109.39-71.47-47.95-115.8-47.95-115.8h-150.64l.57 89.1"
          ></path>
          <path
            fill="#f9b39d"
            d="M8874.3 3017.33s257.66 342.58 364.18 236.06C9345.01 3146.87 8944.63 800 8944.63 800h-262.66s0 1693.95 192.33 2217.33"
          ></path>
          <path
            fill="#131e44"
            d="M7345.28 1790h1138.43s-59.39 285.34-569.21 285.34c-509.81 0-569.22-285.34-569.22-285.34"
          ></path>
          <path
            fill="#131e44"
            d="M8032.79 456.719l-30.03 2.531 249.99 1371.47 29.52-5.37-249.48-1368.631"
          ></path>
          <path
            fill="#131e44"
            d="M7703.13 456.719L7539.11 1839.24l29.8 3.5L7731.7 458.309l-28.57-1.59"
          ></path>
          <path
            fill="#131e44"
            d="M8575.75 462.961L8413.54 1857.67l29.8 3.45 162.58-1397.858-30.17-.301"
          ></path>
          <path
            fill="#fca38b"
            d="M8811.48 3303.42s224.15 286.27 295.17 248.74c71.02-37.53 182.55-339.09 131.84-294.9-64.85 56.48-269.24-143.33-269.24-143.33l-157.77 189.49"
          ></path>
          <path
            fill="#576dc9"
            d="M8165.68 3451.95l516.29 69.67 53.27-163.67-497.62-228.07-71.94 322.07"
          ></path>
          <path
            fill="#60443d"
            d="M8690 1310h338.27s-67.35-475.711-29.56-524.539c92.6-119.66 401.22-192.172 360.42-322.5l-485.94.43-61.71 202.519-31.72-202.5-97.79 1.34 5.44 845.25"
          ></path>
          <path
            fill="#f9b39d"
            d="M8575 3507.19s165.46 183.59 236.48 146.06c71.02-37.53 156.76-286.58 107.12-241.2-165.4 151.23-161.27-76.48-161.27-76.48L8575 3507.19"
          ></path>
          <path
            fill="#fff"
            d="M8575 3469.57s45.58 180.88 90.76 161.55c45.2-19.32 0-191.59 0-191.59s99.25 63.57 109.88 41.83c10.64-21.74-64.68-79.71-64.68-79.71s225.21 37.6 144.95-30.41c-80.27-68.01-247.74-68.81-247.74-68.81L8575 3469.57"
          ></path>
          <path
            fill="#000"
            d="M7698.66 4916.3c-266.82 67.47-356.5 43.18-386.21 22.7-13.74-9.48-15.13-18.34-15.14-18.43l-9.93 1.15c.16 1.35 2.04 13.54 19.4 25.51 37.99 26.2 137.25 43.78 394.33-21.24l-2.45-9.69"
          ></path>
          <path
            fill="#000"
            d="M7657.62 4649.06l-2.28.11c-16.03 1.54-24.98 19.03-25.35 19.78l8.93 4.5c.06-.14 6.89-13.34 17.41-14.33 7.11-.62 14.96 4.43 23.22 15.12l7.92-6.1c-9.77-12.67-19.8-19.08-29.85-19.08"
          ></path>
          <path
            fill="#000"
            d="M7627.2 4933.5c0-17.75-6.01-32.14-13.43-32.14s-13.43 14.39-13.43 32.14 6.01 32.15 13.43 32.15 13.43-14.4 13.43-32.15"
          ></path>
          <path
            fill="#f79e85"
            d="M7420.29 4796.07c-15.55 0-28.15 5.59-28.15 12.48 0 6.89 12.6 12.48 28.15 12.48 15.55 0 28.16-5.59 28.16-12.48 0-6.89-12.61-12.48-28.16-12.48"
          ></path>
          <path
            fill="#f79e85"
            d="M8944.53 2681.82c-.07.44-.16 1.08-.28 1.91L9060 2807.04v-94.68l-115.47-30.54"
          ></path>
          <path
            fill="#e0624c"
            d="M7386.74 2451.19s176.57-523.04 718.21-409.74c431.73 90.3 839.68 639.64 839.68 639.64s-69.11 504.13-209.38 535.31c-378.86 84.22-605.32-150.74-790.57-385.7-189.44-240.25-335.79-480.51-557.94-379.51"
          ></path>
          <path
            fill="#6979d6"
            d="M7386.74 2451.19s266.31-173.05 905.44 202.72c56.89 33.45 652.03 428.92 652.03 428.92l-132.73 220.59L8252.23 3060c-86.55 416.52-227.03 1056.07-399.46 1134.03-292.92 132.45-772.29 187.93-838.86-78.37-47.14-188.55-15.82-1392.99 140.74-1435.12 51.99-14 162.78 28.91 162.78 28.91 0-126.96 18.93-193.9 69.31-258.26"
          ></path>
          <path
            fill="#9b4a3a"
            d="M7368.13 5338.52s47.35-65.1 112.45-47.34c65.1 17.75 212 18.38 219.1-128.84 5.89-122.12-118.98-97.99-130.81-133.5-11.84-35.51-17.74-215.5-88.29-157.86-93.84 76.67-189.81-48.83-136.54-118.5 53.26-69.66 148.37-23.53 154.29-70.88 5.92-47.34-88.77-118.37-41.43-177.55 47.35-59.18 133.3-187.2 81.42-408.36-35.89-152.98-187.95-227.85-342.74-152.98 0 0-47.41-237.62-253.56-237.62-232.39 0-371.76 248.27-106.53 816.72 62.47 133.89-51.41 217.94-5.7 376.15 34.42 119.13 147.74 120.98 159.57 177.2 11.84 56.22 0 399.48 378.77 263.36"
          ></path>
          <path
            fill="#cfafb0"
            d="M6941.1 3705.09c-86.6 0-160.26 34.5-204.9 107.18.71-.69 1.43-1.37 2.15-2.05 44.68-71.17 117.59-105.01 203.19-105.13h-.44"
          ></path>
          <path
            fill="#595794"
            d="M7315.93 3913.4c-38.52 0-79.37 9.19-120.43 28.9.06.27.08.41.08.41 41.21-19.94 82.21-29.25 120.9-29.31h-.55"
          ></path>
          <path
            fill="#833528"
            d="M6941.54 3705.09c-85.6.12-158.51 33.96-203.19 105.13 43.73-41 101.78-60.75 167.24-60.75 206.15 0 253.57 237.62 253.57 237.62 41.34-19.99 82.48-29.31 121.27-29.31 106.44.01 195.16 70.18 221.46 182.3 51.89 221.16-34.07 349.18-81.41 408.36-41.23 51.53 25.21 112.03 39.18 158.07 20.58-2.49 35.52-7.05 37.75-24.91 5.92-47.34-88.77-118.37-41.43-177.55 47.35-59.18 133.31-187.2 81.42-408.36-26.26-111.95-114.7-182.06-220.92-182.29-38.69.06-79.69 9.37-120.9 29.31 0 0-.02-.14-.08-.41-.27.14-.56.27-.83.41 0 0-47.37-237.27-253.13-237.62"
          ></path>
          <path
            fill="#d2806d"
            d="M7505.07 4859.69c-7.39 0-15.8 3.45-25.41 11.29-20.52 16.77-41.13 23.87-60.27 23.99.12.01.25.01.38.01 19.28 0 40.1-7.08 60.81-24 9.41-7.68 17.66-11.14 24.94-11.28-.15-.01-.3-.01-.45-.01"
          ></path>
          <path
            fill="#833528"
            d="M7331.71 4774.17c-8.59 5.75-16.75 13.09-24.1 22.7-41.51 54.29 7.61 142.49 75.73 142.49 19.29 0 40.11-7.08 60.81-23.99 9.61-7.85 18.02-11.3 25.41-11.3 46.86.02 52.65 138.49 62.88 169.16 11.84 35.51 136.7 11.38 130.82 133.49-1.19 24.46-6.28 44.76-14.16 61.65 27.39-20.89 47.17-54.32 49.67-106.03 5.88-122.12-118.99-97.99-130.82-133.5-10.19-30.57-15.98-168.26-62.43-169.14-7.28.14-15.53 3.6-24.94 11.28-20.71 16.92-41.53 24-60.81 24-.13 0-.26 0-.38-.01-.18.01-.36.01-.54.01-59.6 0-104.64-67.56-87.14-120.81"
          ></path>
          <path
            fill="#505fc8"
            d="M8016.47 2515.73l-1.78 6.57c14.85 3.66 27.42 7.04 37.95 10.08-1.78-.91-3.53-1.81-5.22-2.67 0 0-.01-.01-.02-.01-10.43-4.84-20.73-9.49-30.93-13.97m-9.67 35.67l-102.88 379.44c66.86-108.21 123.32-234.24 170.06-360.13-12.13-4.29-33.43-11.03-67.18-19.31m103.12 10.52c-2.48 6.84-5 13.69-7.55 20.53-68.46 184.12-143.22 331.64-223.85 442.07l-170.02 627S8023 2595.4 8155.91 2595.4c3.38 0 6.64.68 9.77 2.08 21.24 9.48 31.16 13.42 32.95 13.42 4.47 0-41.76-24.59-88.71-48.98"
          ></path>
          <path
            fill="#131e44"
            d="M7131.03 460.98l-32.53 2.321 323.34 1409.249c-35.16 79.77-411.15 951.9-194.02 1276.95 55.48 83.04 144.9 123.63 266.14 120.24 236.5-6.36 441.2-237.59 608.41-687.29 123.3-331.62 179.39-665.79 179.94-669.12l-29.6-4.9c-2.18 13.17-224.14 1316.93-759.57 1331.33-110.09 2.78-191-33.03-240.37-106.92-213.65-319.81 194.36-1243.38 198.51-1252.66l2.04-4.58-322.29-1414.62"
          ></path>
          <path
            fill="#131e44"
            d="M7417.52 2448.62c-127.07 0-186.66 15.4-190.31 16.37l7.74 28.99c1.32-.35 134.15-34.19 427.61.4 334.48 39.44 423.76 81.04 424.62 81.46l13.2-26.94c-3.6-1.78-92.17-43.98-434.31-84.31-101.39-11.96-183.75-15.97-248.55-15.97"
          ></path>
          <path
            fill="#131e44"
            d="M7426.53 2636.98c-129.73 0-225.58 10.84-234.6 11.9l3.49 29.8c5.12-.61 513.54-57.98 797.82 95.16l14.23-26.41c-168.22-90.62-405.55-110.45-580.94-110.45"
          ></path>
          <path
            fill="#fff"
            d="M8811.48 3303.42s61.32 112.3 107.12 108.63c45.79-3.66-8.32-175.61-8.32-175.61s81.96 152.81 125.53 117.48c43.58-35.33-76.75-181.5-76.75-181.5s147.59 64.13 147.59 3.84c0-60.52-162.44-93.44-162.44-93.44l-132.73 220.6"
          ></path>
          <path
            fill="#505fc8"
            d="M7247.63 2696.04l-27.74 370.51c6.82 18.93 14.96 36.64 24.55 52.98l72.99-405.74-69.8-17.75m-32.41 432.82l-19.64 262.21 41.19-228.93c-3.08-4.1-6.06-8.31-8.95-12.64-4.44-6.64-8.65-13.55-12.6-20.64"
          ></path>
          <path
            fill="#0f1840"
            d="M7219.89 3066.55l-4.67 62.31c3.95 7.09 8.16 14 12.6 20.64 2.89 4.33 5.87 8.54 8.95 12.64l7.67-42.61c-9.59-16.34-17.73-34.05-24.55-52.98"
          ></path>
          <path
            fill="#d54a37"
            d="M7617.26 2323.39c-114.95 0-175.69 104.73-176.35 105.89.04-.02.09-.03.13-.04 1.55-.53 15.88-5.32 42.18-9.68 26.98-4.78 59.85-8.15 98.92-8.15 109.89 0 268.67 26.66 482.9 123.75 24.97-4.29-209.52-190.25-429.64-210.91-6.18-.58-12.24-.86-18.14-.86"
          ></path>
          <path
            fill="#645b9a"
            d="M7582.14 2411.41c-39.07 0-71.94 3.37-98.92 8.15 23.14-3.83 55.57-7.35 96.7-7.35 98.27 0 246.29 20.04 436.55 103.52l.44-1.59s12.3 6.25 30.51 15.57c3.65 1.69 6.75 2.97 9.34 3.87 1.37.4 2.7.8 4 1.19 1.31.31 2.41.46 3.29.46.36 0 .69-.02.99-.07-214.23-97.09-373.01-123.75-482.9-123.75m-141.1 17.83c-.04.01-.09.02-.13.04-.01 0-.01.01-.01.01s.05-.02.14-.05"
          ></path>
          <path
            fill="#4c4890"
            d="M8016.91 2514.14l-.44 1.59c10.2 4.48 20.5 9.13 30.93 13.97.01 0 .02.01.02.01-18.21-9.32-30.51-15.57-30.51-15.57"
          ></path>
          <path
            fill="#121731"
            d="M8056.76 2533.58c1.5.53 2.84.92 4 1.19-1.3-.39-2.63-.79-4-1.19"
          ></path>
          <path
            fill="#131e44"
            d="M3350.42 1830h1138.43s-59.4 285.34-569.21 285.34c-509.82 0-569.22-285.34-569.22-285.34"
          ></path>
          <path
            fill="#1e2d56"
            d="M3285.99 2240.16s73.05-294.79 418.51-317.79c345.46-23.02 1256.71 416.62 1256.71 416.62L5163.72 680h358.97s-55.22 2395.87-147.27 2470.69c-92.05 74.81-1873.95-636.69-2089.43-910.53"
          ></path>
          <path
            fill="#000"
            d="M5502.11 1460.9l76.62-189.79 793.16 214.32-513.53 101.65-356.25-126.18"
          ></path>
          <path
            fill="#131d3d"
            d="M4991.28 2095.83l-30.07 245.91 73.64 51.2-43.57-297.11"
          ></path>
          <path
            fill="#0c0c0c"
            d="M5571.23 1267.96L4907.12 3027.3l9.36 3.53 664.11-1759.33-9.36-3.54"
          ></path>
          <path
            fill="#ddddfc"
            d="M4571.79 387.961c-900.11 0-1629.8 43.937-1629.8 98.148 0 54.2 729.69 98.141 1629.8 98.141 900.1 0 1629.79-43.941 1629.79-98.141 0-54.211-729.69-98.148-1629.79-98.148"
          ></path>
          <path
            fill="#394766"
            d="M2872.34 4020.4s-295.09-1335.99-157.03-1415.52c138.07-79.53 372.79 188.46 372.79 188.46s-279.65 843.32-215.76 1227.06"
          ></path>
          <path
            fill="#243c70"
            d="M3765.94 2584.07S4937.7 3737.64 5044.06 3708.43c248.52-68.27 816.62-2121.13 816.62-2121.13l-356.4-128.62-592.48 1570.38-875.64-351.3-270.22-93.69"
          ></path>
          <path
            fill="#f9b39d"
            d="M3028.47 4335.19s-116.52 701.8 20.28 812.25c136.8 110.46 265.82 174.82 265.82 174.82s83-244.7 141.82-347.63c36.81-64.43 138.01-91.96 188.64-144.49 23.05-23.94-151.91-53.27-151.91-53.27s65.5-225.46-.01-262.31c-128.87-72.49-246.77 21.58-246.77 21.58l75.25-236.14H3030l-1.53 35.19"
          ></path>
          <path
            fill="#46557c"
            d="M3287.64 2201.73s574.76-35.12 781.86 268.88c56.01 82.2-372.02 981.43-372.02 981.43s614.29-686.15 775.37-599.09c137.07 74.09 249.04 290.16 364.76 723.53l-199.52 22.48-225-333.73s-802.52 1037.65-1091.5 1037.65c-382.26 0-297.92 71.48-339.95 0-119.06-202.5-440.59-291.87 306-2101.15"
          ></path>
          <path
            fill="#a52f0f"
            d="M3000.65 4514.58s105.85 0 151.05 110.46c20.09 49.08 51.46 151.87 51.46 151.87s-115.07 166.92 0 221.83c86.72 41.4 107.69-118.74 107.69-118.74h57.98s-101.25 308.68 36.82 431.17c82.93 73.57-63.26 48.03-223.17 22.49-106.33-16.98-218.73-33.96-273.87-21.8-138.07 30.44-119.66.34-119.66.34l73.63-84.66s-331.36-372.39 138.07-712.96"
          ></path>
          <path
            fill="#d3a59f"
            d="M3349.98 5222.94c11.27 33.98 29.01 64.58 55.67 88.23 16.18 14.35 23.63 24.93 23.97 32.48.17-7.15-7.23-17.63-23.97-32.48-26.66-23.65-44.4-54.25-55.67-88.23"
          ></path>
          <path
            fill="#d67962"
            d="M3368.83 4880h-57.98s-4.16 29.01-16.36 61.21c0 0 .44-.48 1.25-1.35 11.25-30.39 15.11-59.86 15.11-59.86h57.98"
          ></path>
          <path
            fill="#8e2009"
            d="M3368.83 4880h-57.98s-3.86 29.47-15.11 59.86c7.06-7.61 42.01-44.83 50.53-44.83 1.32 0 2.01.9 1.86 2.93-7.77 104.46-108.5 325 4.01 457.1 14.59 1.22 27.61 1.95 38.62 1.95 24.14 0 38.63-3.55 38.86-13.36-.34-7.55-7.79-18.13-23.97-32.48-26.66-23.65-44.4-54.25-55.67-88.23-12.37-37.33-16.94-78.73-16.94-119.5-.02-114.26 35.79-223.44 35.79-223.44"
          ></path>
          <path
            fill="#d3a59f"
            d="M2862.41 5227.34l-73.46 84.06s-.91 1.45-.79 3.37c.14-1.51.79-2.57.79-2.57l73.63-84.66-.17-.2"
          ></path>
          <path
            fill="#8e2009"
            d="M2862.58 5227.14l-.17.2.17.2-73.63 84.66s-.65 1.06-.79 2.57c.16 2.56 2.12 5.95 10.47 7.86l27.14-31.06s42.64-56.93 36.81-64.43"
          ></path>
          <path
            fill="#d3a59f"
            d="M2999.42 4515.48c-.02.02-.05.04-.07.05.03-.02.04-.03.07-.05m-1.24.9c-.05.04-.1.07-.15.11.06-.04.09-.07.15-.11m-1.26.93c-.06.04-.13.09-.19.14.05-.05.13-.1.19-.14m-1.29.94c-.07.05-.13.1-.2.14.06-.04.14-.1.2-.14m-1.29.95c-.07.05-.12.09-.19.14.06-.04.13-.1.19-.14m-1.27.94l-.21.15c.09-.06.13-.09.21-.15m-1.27.94c-.07.06-.15.11-.22.16.08-.06.13-.1.22-.16m-1.19.89c-.09.06-.16.12-.25.18.08-.06.16-.12.25-.18m-1.22.91c-.09.06-.16.11-.24.18.08-.07.16-.12.24-.18m-1.23.91c-.1.08-.2.15-.3.23.08-.06.22-.16.3-.23m-1.24.94c-.12.09-.24.17-.36.27.14-.11.22-.17.36-.27m-1.24.93c-.13.1-.27.21-.41.31.14-.11.27-.21.41-.31m-1.25.95l-.45.33c.14-.1.31-.23.45-.33m-1.16.88c-.19.14-.38.28-.57.43.19-.15.37-.29.57-.43m-1.13.86c-.25.19-.5.37-.75.56l.75-.56m-1.16.88l-.87.66c.27-.2.59-.45.87-.66m-1.25.95c-.32.25-.65.51-.98.76.33-.25.66-.51.98-.76m-1.21.94c-.41.31-.82.63-1.23.94.41-.31.82-.63 1.23-.94m-1.24.95c-96.03 74.14-155.23 149.42-189.2 221.74l.07-.1c33.98-72.3 93.17-147.55 189.13-221.64"
          ></path>
          <path
            fill="#d67962"
            d="M3004.07 4514.72c18.49 1.12 107.26 11.65 147.63 110.32 20.09 49.08 51.46 151.87 51.46 151.87s-31.37-102.79-51.46-151.87c-40.37-98.67-129.14-109.2-147.63-110.32m160.38 338.9c-18.31 51.44-24.28 115.06 38.71 145.12 9.92 4.74 18.99 6.84 27.26 6.84 17.33 0 31.18-9.22 42.16-22.66.66-2.32 1.36-4.64 2.09-6.94-11.06 9.43-22.7 28.02-44.16 28.02-7.71 0-16.69-2.4-27.35-8.48-58.95-33.59-55.16-93.34-38.71-141.9"
          ></path>
          <path
            fill="#8e2009"
            d="M3000.65 4514.58l-1.23.9c-.03.02-.04.03-.07.05-.39.28-.78.57-1.17.85-.06.04-.09.07-.15.11-.37.27-.74.54-1.11.82-.06.04-.14.09-.19.14-.37.26-.74.53-1.1.8-.06.04-.14.1-.2.14-.36.27-.73.54-1.09.81-.06.04-.13.1-.19.14-.35.26-.72.53-1.08.8-.08.06-.12.09-.21.15-.35.27-.71.53-1.06.79-.09.06-.14.1-.22.16l-.97.73c-.09.06-.17.12-.25.18l-.97.73c-.08.06-.16.11-.24.18-.33.24-.66.49-.99.73-.08.07-.22.17-.3.23-.32.24-.63.47-.94.71-.14.1-.22.16-.36.27-.29.22-.59.44-.88.66-.14.1-.27.2-.41.31-.28.21-.56.42-.84.64-.14.1-.31.23-.45.33-.23.18-.48.37-.71.55-.2.14-.38.28-.57.43-.18.14-.38.29-.56.43l-.75.56-.41.32c-.28.21-.6.46-.87.66-.13.1-.25.2-.38.29-.32.25-.65.51-.98.76-.08.06-.15.11-.23.18-.41.31-.82.63-1.23.94 0 0 0 .01-.01.01-95.96 74.09-155.15 149.34-189.13 221.64 39.06-57.8 74.18-98.62 175.68-174.7 19.19-14.38 36.57-21.14 52.79-21.14 40.88 0 74.35 43 110.6 115.49 26.77 53.55 46.02 121.96 46.02 121.96s-112.82 200.09 6.9 243.92c17.4 6.37 31.04 9.14 41.86 9.14 37.36 0 41.25-33.03 50.58-65.46-10.98 13.44-24.83 22.66-42.16 22.66-8.27 0-17.34-2.1-27.26-6.84-62.99-30.06-57.02-93.68-38.71-145.12 14.46-42.68 38.71-76.71 38.71-76.71s-31.37-102.79-51.46-151.87c-40.37-98.67-129.14-109.2-147.63-110.32-2.21-.14-3.42-.14-3.42-.14"
          ></path>
          <path fill="#000" d="M5160 680V460h878.14l-515.45 220H5160"></path>
          <path
            fill="#4f628e"
            d="M3019.26 4391.68l-89.59-163.45s420.75 22.6 654.75-63.14c81.65-29.91-281.52 204.26-281.52 204.26l-283.64 22.33"
          ></path>
          <path
            fill="#f3d8d8"
            d="M3293.12 4391.68l-1.94 3.74-44.84 140.72s.21-.16.61-.47l46.17-143.99"
          ></path>
          <path
            fill="#f79e85"
            d="M3291.18 4395.42l-97.23 188.2 52.38-46.02.62-1.93c-.4.31-.61.47-.61.47l44.84-140.72"
          ></path>
          <path
            fill="#2d395e"
            d="M3784.04 3265.23l-346.94 433.29 260.38-246.48 86.56-186.81"
          ></path>
          <path
            fill="#f79e85"
            d="M3237.29 4849.58c-7.09 0-15.8 6.47-21.45 16.67-7.02 12.7-6.63 26.36.88 30.51 1.48.82 3.12 1.21 4.86 1.21 7.1 0 15.81-6.48 21.45-16.68 7.02-12.69 6.63-26.35-.88-30.51-1.48-.81-3.12-1.2-4.86-1.2"
          ></path>
          <path
            fill="#070100"
            d="M3414.46 4971.88c5.06-17.73 4.71-33.38-.79-34.95-5.5-1.57-14.06 11.53-19.12 29.26-5.07 17.73-4.72 33.38.78 34.95 5.5 1.57 14.06-11.53 19.13-29.26"
          ></path>
          <path
            fill="#070100"
            d="M3463.06 4688.82c-17.51 0-35.4 24.69-37.51 27.69l8.18 5.75c7.07-10.03 21.78-25.25 30.92-23.27 6.69 1.41 10.24 12.4 12.03 21.38l9.81-1.97c-3.47-17.34-10.12-27.17-19.78-29.2-1.21-.26-2.43-.38-3.65-.38"
          ></path>
          <path
            fill="#131e44"
            d="M4008.04 450h-31.79l252.25 1405.82 29.53-5.96L4008.04 450"
          ></path>
          <path
            fill="#131e44"
            d="M3638.49 450l-162.8 1386.23 29.79 2.59L3668.28 450h-29.79"
          ></path>
          <path
            fill="#131e44"
            d="M4496.87 450l-162.8 1386.23 29.79 2.59L4526.66 450h-29.79"
          ></path>
          <path
            fill="#121d3b"
            d="M4797.77 2983.31l.01.01 109.38 43.88-109.39-43.89"
          ></path>
          <path fill="#070808" d="M4907.16 3027.2l4.64 1.86-4.64-1.86"></path>
          <path
            fill="#16264d"
            d="M4797.78 2983.32l99 111.47 15.02-65.73-4.64-1.86-109.38-43.88"
          ></path>
          <path
            fill="#f9b39d"
            d="M4638.09 3598.96s121.67 209.07 192.41 214.76c130.72 10.52 254.98-122.54 254.98-122.54s-164.47-23.41-248.3-122.57l-199.09 30.35"
          ></path>
          <path
            fill="#141e3f"
            d="M3557.3 2159.15c-2.64 0-5.28.01-7.95.04-101.8 1.15-234.03 41.31-234.03 41.31s3.72 0 10.51.09c5.01-.06 10.45-.1 16.31-.1 62.44 0 171.41 4.11 289.26 27.3 22.45 4.2 44.33 9.05 64.93 14.64 96.41 24.65 194.08 63.41 273.39 124.11-43.93-35.63-129.36-207.39-412.42-207.39"
          ></path>
          <path
            fill="#2d395b"
            d="M3342.14 2200.49c-5.86 0-11.3.04-16.31.1 39.22.51 181.19 3.94 305.57 27.2-117.85-23.19-226.82-27.3-289.26-27.3m354.19 41.94c.19.05.38.1.56.15 97.16 26.47 185.21 59.97 274.92 125.57-.69-.5-1.38-1.04-2.09-1.61-79.31-60.7-176.98-99.46-273.39-124.11"
          ></path>
          <path
            fill="#39476d"
            d="M3857.17 2448.99c-174.86 0-219 348.76-219 348.76l250.1 70.23s189.02-339.91 31.37-405.44c-22.47-9.34-43.27-13.55-62.47-13.55"
          ></path>
          <path
            fill="#131e44"
            d="M3102.71 452.031l-29.23 6.719 324.12 1412.62c-35.16 79.76-411.15 951.9-194.02 1276.95 55.48 83.04 145.06 123.63 266.13 120.24 236.51-6.36 441.21-237.59 608.42-687.28 123.3-331.62 179.38-665.79 179.93-669.12l-29.6-4.9c-2.18 13.17-224.15 1316.92-759.56 1331.32-109.91 2.8-190.99-33.02-240.37-106.92-213.65-319.81 194.36-1243.38 198.51-1252.66l2.04-4.58-326.37-1422.389"
          ></path>
          <path
            fill="#131e44"
            d="M3393.28 2447.44c-127.07 0-186.66 15.39-190.32 16.37l7.74 28.98c1.32-.34 134.14-34.19 427.61.41 332.14 39.16 420.85 107.86 421.7 108.54l19.07-23.15c-3.65-3.03-93.29-74.63-437.25-115.18-101.4-11.96-183.75-15.97-248.55-15.97"
          ></path>
          <path
            fill="#131e44"
            d="M3276.98 2646.11c-60.83 0-101.63 1.28-108.04 1.5l1 29.98c6.48-.21 650.83-20.21 814.07 108.05l18.53-23.6c-130.91-102.84-530.76-115.93-725.56-115.93"
          ></path>
          <path
            fill="#0c0c0c"
            d="M3119.85 2612.18c-3.43 9.56-342.94 959.73-312.62 1301.07 15.07 169.71 117.32 316.4 118.35 317.86l8.17-5.76c-1.01-1.44-101.73-146.01-116.56-312.98-30.13-339.17 308.64-1287.27 312.07-1296.81l-9.41-3.38"
          ></path>
        </g>
      </g>
    </svg>
    );
  }
}