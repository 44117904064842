/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Ivo Marrero",
  logo_name: "Ivo Marrero",
  nickname: "Ivo",
  subTitle:
    "Creating value as a Trader & Project Manager 🚀. Background in Financial Engineering, Blockchain, Sales & Communication.",
  resumeLink:
    "https://drive.google.com/file/d/1JPLPKAMvrAFHEgPZDd6eAAhJ2Tc1puR1/view?usp=sharing",
  portfolio_repository: "https://github.com/ivomarrero.github.io",
};

const socialMediaLinks = [
  /* Your Social Media Links */
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ivo-m-a08695100/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:hello@ivomarrero.com",
    fontAwesomeIcon: "fa fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Communicate Progress",
      fileName: "CommunicateImg",
      skills: [
        "⚡ Agile implementation of visions into sophisticated technology.",
        "⚡ Setting metrics of business success KPI’s and keeping track of progress.",
        "⚡ Ideating frameworks to focus on the important and delivering functionality.",
      ],
      softwareSkills: [
    //     {
    //       skillName: "HTML5",
    //       fontAwesomeClassname: "simple-icons:html5",
    //       style: {
    //         color: "#E34F26",
    //       },
    //     },
    //     {
    //       skillName: "CSS3",
    //       fontAwesomeClassname: "fa-css3",
    //       style: {
    //         color: "#1572B6",
    //       },
    //     },
    //     {
    //       skillName: "JavaScript",
    //       fontAwesomeClassname: "simple-icons:javascript",
    //       style: {
    //         backgroundColor: "#000000",
    //         color: "#F7DF1E",
    //       },
    //     },
    //     {
    //       skillName: "ReactJS",
    //       fontAwesomeClassname: "simple-icons:react",
    //       style: {
    //         color: "#61DAFB",
    //       },
    //     },
    //     {
    //       skillName: "Flutter",
    //       fontAwesomeClassname: "simple-icons:flutter",
    //       style: {
    //         color: "#02569B",
    //       },
    //     },
    //     {
    //       skillName: "Swift",
    //       fontAwesomeClassname: "simple-icons:swift",
    //       style: {
    //         color: "#FA7343",
    //       },
    //     },
    //     {
    //       skillName: "NodeJS",
    //       fontAwesomeClassname: "simple-icons:node-dot-js",
    //       style: {
    //         color: "#339933",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "simple-icons:python",
    //       style: {
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Java",
    //       fontAwesomeClassname: "simple-icons:java",
    //       style: {
    //         color: "#F96F29",
    //       },
    //     },
    //     {
    //       skillName: "MySQL",
    //       fontAwesomeClassname: "simple-icons:mysql",
    //       style: {
    //         color: "#4479A1",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
       ],
     },
     {
      title: "Preserve capital, Generate returns.",
      fileName: "GenerateImg",
      skills: [
        "⚡ Spec out fundamental and analytical tools to understand market behavior in a systematic method and take calculated risks based on proprietary data.",
        "⚡ Adapt to ever-changing market conditions and allocate according to priorities.",
        "⚡ Monitoring trading systems and enhancing its performance.",
      ],
       softwareSkills: [
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Azure",
    //       fontAwesomeClassname: "simple-icons:microsoftazure",
    //       style: {
    //         color: "#0089D6",
    //       },
    //     },
    //     {
    //       skillName: "Google Cloud",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#FFBB00",
    //       },
    //     },
    //     {
    //       skillName: "Heroku",
    //       fontAwesomeClassname: "simple-icons:heroku",
    //       style: {
    //         color: "#430098",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Linux",
    //       fontAwesomeClassname: "simple-icons:linux",
    //       style: {
    //         color: "#FCC624",
    //       },
    //     },
        ],
      },
    {
      title: "Intra-entrepreneurship",
      fileName: "EntreImg",
      skills: [
        "⚡ Spin off projects in different departments to propell companies growth & success.",
        "⚡ Created a High Net-Worth referral system that accurately predicts the customer acquisition cost and enabled it to decrease by a factor of 10.",
        "⚡ Presented at an influencer event with a total following of 3M social media followers.",
        "⚡ Kicked off a handbook of effective guidelines & solutions for the hybrid work environment in the new world.",
      ],
      softwareSkills: [
        // {
        //   skillName: "Tensorflow",
        //   fontAwesomeClassname: "logos-tensorflow",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        // {
        //   skillName: "Jupyter Notebook",
        //   fontAwesomeClassname: "simple-icons:jupyter",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#F37626",
        //   },
        // },
        // {
        //   skillName: "PyTorch",
        //   fontAwesomeClassname: "logos-pytorch",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "ion-logo-python",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#3776AB",
        //   },
        // },
        // {
        //   skillName: "R",
        //   fontAwesomeClassname: "simple-icons:r",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#276DC3",
        //   },
        // },
      ],
    },
    {
      title: "Public Speaking, Summits & Conferences",
      fileName: "SpeakingImg",
      skills: [
        "⚡ Energized extrovert open to speak persuasively about needs or solutions to be implemented.",
        "⚡ Raised money from investors.",
        "⚡ Pitched infront of a crowd of 120 young professionals and many other investor calls.",
        "⚡ Constantly selected to represenent companies at Summit: The Next Web, Web Summit, MWC, Bitcoin 2022, Battle of the Quants and more ...",
      ],
      softwareSkills: [
        // {
        //   skillName: "Adobe XD",
        //   fontAwesomeClassname: "simple-icons:adobexd",
        //   style: {
        //     color: "#FF2BC2",
        //   },
        // },
        // {
        //   skillName: "Figma",
        //   fontAwesomeClassname: "simple-icons:figma",
        //   style: {
        //     color: "#F24E1E",
        //   },
        // },
        // {
        //   skillName: "Framer",
        //   fontAwesomeClassname: "simple-icons:framer",
        //   style: {
        //     color: "#0055FF",
        //   },
        // },
        // {
        //   skillName: "Invision",
        //   fontAwesomeClassname: "simple-icons:invision",
        //   style: {
        //     color: "#FF3366",
        //   },
        // },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/",
    // },
    // {
    //   siteName: "FreeCodeCamp",
    //   iconifyClassname: "simple-icons:freecodecamp",
    //   style: {
    //     color: "#0A0A23",
    //   },
    //   profileLink: "https://www.freecodecamp.org/",
    // },
    // {
    //   siteName: "Exercism",
    //   iconifyClassname: "simple-icons:exercism",
    //   style: {
    //     color: "#009CAB",
    //   },
    //   profileLink: "https://exercism.io/",
    // },
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F89F1B",
    //   },
    //   profileLink: "https://www.leetcode.com/",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "MSc Blockchain & Cryptocurrencies",
      subtitle: "Mundo Crypto",
      logo_path: "mundocrypto.png",
      alt_name: "Mundocrypto",
      duration: "2022",
      descriptions: [
        "⚡ Key modules: DeFi, DAO’s, Tokenization, NFT, New World Economics, Blockchain tech Analysis, On-Chain analysis, Gaming & Metaverse, Pre-sales, Investing Psychology & Entrepreneurship",
      ],
      website_link: "https://academia.mundocrypto.com/",
    },
    {
      title: "BSc Industrial Engineering w/ Financial Engineering",
      subtitle: "Purdue University",
      logo_path: "purdue.png",
      alt_name: "Purdue University",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ Key Modules: Calculus, Stochastic models, Integrated production systems, Optimization, Computing, Econometrics, Predictive Analysis, Portfolio Management & Black Scholes",
        "⚡ Distinctions: Dean list.",
        "⚡ Clubs & society: Poker club, Chess club, Trading & Finance club , Buddhist society, Latino society. ",
      ],
      website_link: "https://www.purdue.edu/",
    },
    {
      title: "Minor in Economics",
      subtitle: "Purdue University",
      logo_path: "purdue.png",
      alt_name: "Purdue University",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ Key Modules: Game Theory, Money theory and banking system, Monetary policies, Micro & Macro economics, Mathematical interest theory",
        "⚡ Distinctions: Dean’s & Honor’s list",
        "⚡ Clubs & society: Poker club, Chess club, Trading & Finance club ,Buddhist society, Latino society.",
      ],
      website_link: "https://www.purdue.edu/",
    },
    {
      title: "High School ",
      subtitle: "Heidelberg Schule",
      logo_path: "heidelberg.png",
      alt_name: "Heidelberg",
      duration: "2005 - 2016",
      descriptions: [
        "⚡ Spanish, English and German trilingual school. 110 TOEFL level in English (2015) and B1 in German.",
        "⚡ Simultaneously completed the International Baccalaureate (IB) , Spanish Baccalaureate and American S.A.T",
      ],
      website_link: "https://www.heidelberg.de/hd/HD/Lernen+und+Forschen/Schulen.html",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Project Manager Certificate",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link: "https://grow.google/certificates/project-management/#?modal_active=none",
      alt_name: "Google",
      color_code: "#8C151599",
    },
    {
      title: "PSPO I ",
      subtitle: "- Srcum.org",
      logo_path: "scrum.png",
      certificate_link: "https://www.scrum.org/",
      alt_name: "Coursera",
      color_code: "#0C9D5899",
    },
    {
      title: "Straight Line Persuasion",
      subtitle: "- Jordan Belfort",
      logo_path: "jb.png",
      certificate_link: "https://jb.online/products/straight-line-persuasion",
      alt_name: "Coursera",
      color_code: "#0C9D5899",
    },
    {
      title: "Personality Psychology",
      subtitle: "- Jordan Peterson",
      logo_path: "jp.png",
      certificate_link: "https://courses.jordanbpeterson.com/personality",
      alt_name: "Coursera",
      color_code: "#0C9D5899",
    },
    {
      title: "Python Data Studies",
      subtitle: "- Coursera",
      logo_path: "coursera.png",
      certificate_link: "https://www.coursera.org/specializations/data-science-python",
      alt_name: "Coursera",
      color_code: "#0C9D5899",
    },
    {
      title: "Macro Sturcture Trader",
      subtitle: "- Phantom",
      logo_path: "phantom.jpeg",
      certificate_link: "https://www.phantomtradingfx.com",
      alt_name: "Phantom",
      color_code: "#0C9D5899",
    },
    {
      title: "Micro Sturcture Trader",
      subtitle: "- Phantom",
      logo_path: "phantom.jpeg",
      certificate_link: "https://www.phantomtradingfx.com",
      alt_name: "Phantom",
      color_code: "#00000099",
    },
    {
      title: "BHV Medical Emergencies",
      subtitle: "- BHV",
      logo_path: "bhv.png",
      certificate_link: "https://www.bhvnederland.nl/cursussen/bhv-basis-e-learning-engelstalig?gclid=Cj0KCQiArt6PBhCoARIsAMF5wah0hZqmOCjq21YooS_RlVDnyKH4o6eneejATgzyy3GTUnb48ru7St0aAly_EALw_wcB",
      alt_name: "BHV",
      color_code: "#1F70C199",
    },
    {
      title: "Cruz Roja Volunteer",
      subtitle: "- Cruz Roja",
      logo_path: "cruzroja.jpeg",
      certificate_link: "https://www2.cruzroja.es/voluntariado",
      alt_name: "Cruz Roja",
      color_code: "#D83B0199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Product owner, Desk Trader, Market Analyst & Sales",
  description:
    "The intersection between Blockchain, Decentralization & Finance to help shape the new world.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Desk Trader",
          company: "BOTS",
          company_url: "www.bots.io",
          logo_path: "bots.svg",
          duration: "May 2021 - PRESENT",
          location: "Harleem",
          description:
            "For BOTS, I have had the amazing opportunity to build a Trading Desk from scratch along with V.P. Market Makers with 40 years of experience & currently oversee $800M of monthly volume through our quoting. The desk runs with a correlation matrix with over 2000 markets and is fully automated. \t\n\n On my day to day I manage risk by ensuring that all parameters are within the established limits, tweaking them in situations in which market conditions change. I then review trading data with Python and test & implement new ideas for more profitable trading with an internal and external teams of developers. We are aiming to bring up our quoting volume and hedging efficiency to levels that would increase the margins of the company by 100%. \t\n\n Weekly, I communicate results to board members and I am frequently chosen to represent the company's best interest in conferences and summits due to my keen interest in trading, blockchain & entrepreneurship. I have also started projects in different departments as ideas bounce around my head to sharpen the arrow of the business.",
          color: "#0879bf",
        },
        {
          title: "Co-Founder & Product Owner",
          company: "AlgoWealth Technologies",
          company_url: "https://www.algowealth.net",
          logo_path: "aw_logo.jpeg",
          duration: "October 2018 - September 2021",
          location: "San Francisco, California, USA",
          description:
            "Alongside a team of developers I recruited to turn a vision into reality, we built an IOS App that connected to more than a dozen back-end trading algorithms in the FOREX market & sent trading signals to manage funds. \t\n\n My role was to perform quant research to turn manual price action trading strategies into indicators for which automated algorithms could make trading decisions, creating tailormade solutions to market analytics, as well as managing the developers with the Scrum Agile method and keeping track of project timelines and the different iterations of the M.V.P. \t\n\n Besides my technical role, I also dealt with external communication: with investors from whom we raised $50,000 from, news articles, pitches and team acquisition strategies. \t\n\n We realized multiple six-figures in trading profits before a slip in risk management coinciding with the break-out of Covid-19 wiped out our account and got stinged with one of the biggest lessons I carry myself with. As the leader of this venture I communicated to investors who deposited around $50,000 how an accumulation of mistakes have resulted in a disastrous effect with bravery and refunded their stop-loss amounts",
          color: "#527189",
        },
        {
          title: "Market Analyst & Cryptocurrency Researcher",
          company: "Vickers Ventures Capital",
          company_url: "",
          logo_path: "vvp.svg",
          duration: "August 2017 - August 2018",
          location: "Singapore",
          description:
            "Being a Market Analyst & Cryptocurrency researcher at a top tier venture capital firm brought me all the way to Singapore while being just 19 years old. In my role, I analyzed and scrutinized markets and macro trends in ASEAN-7 predicting future shifts. \t\n\n We aimed to invest in the best startups, therefore I was also evaluating market situations and financial status for startups going through funding rounds in our portfolio or new companies that applied to receive funding, having regular conferences with entrepreneurs and investors.\t\n\n As this happened in 2017, I got exposed to cryptocurrencies for the first time. This happened to be a pivoting event for my life. My interest was such that I find my way through to convincing my manager to let me present cryptocurrency’s use cases and ICO’s to all employees",
          color: "#000FAD",
        },
        {
          title: "Undergraduate teacher",
          company: "Purdue University",
          company_url: "",
          logo_path: "purdue.png",
          duration: "Spring 2017",
          location: "Purdue University, West Lafayette",
          description:
            "Especially during my first year of college, I focused on getting good grades. I achieved an A+ in Physics 172, considered one of the toughest engineering classes of the United States and became U.T.A the following semester. In my role, I prepared presentations, organized Q&A’s and helped ±30 students prepare for their exams.",
          color: "#B11121",
        },
        {
          title: "Jetski Monitor & Sales Rep",
          company: "Canary Watersports",
          company_url: "",
          logo_path: "canary.jpeg",
          duration: "Summers 2013, 2014, 2015 ,2016, 2018",
          location: "Canary Watersports, Las Palmas De Gran Canaria",
          description:
            "During the tenure of my life at my home town, Canary Islands; I was looking for a job in which I could practice my languages, work towards the public and was blasting fun. I landed a job as a sales rep of Canary Watersports, a Jet Ski rental company. I represented my company in booths by the beach & attracted clients to sell them the watersports we offered in spanish, english or german. \t\n\n At some point I got jealous of the older colleagues who were riding up & down in Jet Skis so I signed up for the boating license and nowadays I am licensed to drive boats of up to 24m all over europe. My position then evolved to be responsible for the fun and safety of up to 20 clients in Jetski Safaris. And of course take pictures of their incredibly fun time ;) !!!",
          color: "#2C3553",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Market Analyst & Cryptocurrency Researcher",
    //       company: "Vickers Ventures Capital",
    //       company_url: "",
    //       logo_path: "vvp.svg",
    //       duration: "2017",
    //       location: "Singapore",
    //       description:
    //         "Being a Market Analyst & Cryptocurrency researcher at a top tier venture capital firm brought me all the way to Singapore for a summer while being just 18 years old. In my role, I analyzed and scrutinized markets and macro trends in ASEAN-7 predicting future shifts. We aimed to invest in the best startups, therefore I was also evaluating market situations and financial status for startups going through funding rounds in our portfolio or new companies that applied to receive funding, having regular conferences with entrepreneurs and investors. As this happened in 2017, I got exposed to cryptocurrencies for the first time. This happened to be a pivoting event for my life. My interest was such that I find my way through to convincing my manager to let me present cryptocurrency’s use cases and ICO’s to all employees",
    //       color: "#000FAD",
    //     },
    //   ],
    // },
    {
      title: "Volunteering & Leadership",
      experiences: [
        {
          title: "Senior Design; Cryptocurrency Machine Learning",
          company: "Purdue University",
          company_url: "",
          logo_path: "purdue.png",
          duration: "Spring 2020",
          location: "West Lafayette, Indiana, U.S.A",
          description:
            "For our senior design class at my Industrial Engineering Bachelors, we were due to work with a company which assigned us a capstone project for graduation. As I realized there were no trading companies I quickly lost interest. Not willing to work on something I didn’t find motivating, I convinced the academic advisors to let me build my own senior design project and was the only student out of a 270 graduating class to put a senior design project & team together. After pitching my own project in front of 270 students (My biggest public speech thus far), I recruited a team with 3 A+ students and one of my best friends from class to create Machine Learning models that recommended buying or selling cryptocurrencies based on their Market Structure trend direction. None of them had prior experience in Markets and since then they have built their own crypto portfolio.",
          color: "#B31417",
        },
        {
          title: "Volunteer",
          company: "NGO Castelos do Sal.",
          company_url: "",
          logo_path: "ngo.jpeg",
          duration: "Summer 2015",
          location: "sland of Sal, Cape Verde",
          description:
            "During this life opening experience I lived in an orphanage in Africa for 10 days. Me and other two schoolmates communicated with children at risk of social exclusion while preparing playful activities for all of us to enjoy. We also tutored spanish, helped remodel infrastructure at the orphanage and even learned how to COOK!",
          color: "#BDD04C",
        },
        {
          title: "Markets for anyone",
          company: "Heidelberg",
          company_url: "",
          logo_path: "heidelberg.png",
          duration: "2017",
          location: "Las Palmas de Gran Canaria",
          description:
            "In order to keep in touch with my high school community, I gave a brief presentation to younger schoolmates of my high school about the history of financial markets and opportunities they bring. I then created a small crash course on how to read financial markets price action and make estimations of where price could go.",
          color: "#fc1f20",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Interests & Events",
  description:
    "My interests arise from my core values of impact and freedom. I want to be involved in projects in which the mainstream adoption of the product or service will alter the way people interact with the world and cause great benefits in their lives.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "ivo-nft-big.png",
    description:
      "I can help with anything blockchain related: DAO’s, tokenization, DeFi… Trading related: Crypto markets or traditional markets & project management or project consultation ",
  },
  addressSection: {
    title: "Address",
    subtitle: "Amsterdam, NL 🇳🇱 | See Ranch, CA 🇺🇸 | Gran Canaria, SP 🇪🇸",
    avatar_image_path: "address_image.svg",
    location_map_link:
      "https://goo.gl/maps/MpMqtRhttps://www.google.com/maps/place/Lancaster/@54.0452479,-2.7944289,13.48z/data=!4m5!3m4!1s0x487b6279a8e97dbd:0xe030ef3d8b443c41!8m2!3d54.04874!4d-2.807454ZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+34 618583608 / +1 765714533",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
